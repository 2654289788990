import IndividualClient from "../../backend/IndividualClient";
import {produce} from "immer";
import {toast} from "react-toastify";
import Family from "../../models/Family/Family";
import FamilyClient from "../../backend/FamilyClient";

export const types = {
    INIT: "FAMILIES/INIT",
    FETCH_FAMILIES_SUCCESS: "FAMILIES/FETCH_FAMILIES_SUCCESS"
};

export const initialState = {
    list: []
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.FETCH_FAMILIES_SUCCESS:
            draft.list = action.families?.map(family => Family.createFromBackend(family)) ?? [];
    }
    return draft;
}, initialState);

export const fetchFamilies = () => dispatch => {
    const familyClient = new FamilyClient();

    return familyClient.fetchFamilies()
        .then(response => response.data)
        .then(data => dispatch({
            type: types.FETCH_FAMILIES_SUCCESS,
            families: data.data
        }))
        .catch(error => {
            toast.error(`Fehler beim Abruf der Familien: ${error}`);
            console.log(error);
        });
};