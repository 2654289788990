import {produce} from "immer";

export const types = {
    EVENT_INDIVIDUAL_SAVED: "EVENT/EVENT_INDIVIDUAL_SAVED",
    EVENT_INDIVIDUAL_EVENT_SAVED: "EVENT/EVENT_INDIVIDUAL_EVENT_SAVED",
    EVENT_RELATIONSHIP_SAVED: "EVENT/EVENT_RELATIONSHIP_SAVED",
    EVENT_RELATIONSHIP_CHILD_SAVED: "EVENT/EVENT_RELATIONSHIP_CHILD_SAVED",
    EVENT_RELATIONSHIP_CHILD_REMOVED: "EVENT/EVENT_RELATIONSHIP_CHILD_REMOVED",
    EVENT_RELATIONSHIP_REMOVED: "EVENT/EVENT_RELATIONSHIP_REMOVED"
};

export const initialState = {
    individualEvent: null
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.EVENT_INDIVIDUAL_SAVED:
            draft.individualEvent = Date.now();
        case types.EVENT_INDIVIDUAL_EVENT_SAVED:
            draft.individualEvent = Date.now();
        case types.EVENT_RELATIONSHIP_SAVED:
            draft.individualEvent = Date.now();
        case types.EVENT_RELATIONSHIP_CHILD_SAVED:
            draft.individualEvent = Date.now();
        case types.EVENT_RELATIONSHIP_CHILD_REMOVED:
            draft.individualEvent = Date.now();
        case types.EVENT_RELATIONSHIP_REMOVED:
            draft.individualEvent = Date.now();
    }
    return draft;
}, initialState);

export const eventIndividualSaved = () => dispatch => {
    return dispatch({
        type: types.EVENT_INDIVIDUAL_SAVED,
    })
};
export const eventIndividualEventSaved = () => dispatch => {
    return dispatch({
        type: types.EVENT_INDIVIDUAL_EVENT_SAVED,
    })
};
export const eventRelationshipSaved = () => dispatch => {
    return dispatch({
        type: types.EVENT_RELATIONSHIP_SAVED,
    })
};
export const eventRelationshipChildSaved = () => dispatch => {
    return dispatch({
        type: types.EVENT_RELATIONSHIP_CHILD_SAVED,
    })
};

export const eventRelationshipChildRemoved = () => dispatch => {
    return dispatch({
        type: types.EVENT_RELATIONSHIP_CHILD_REMOVED,
    })
};
export const eventRelationshipRemoved = () => dispatch => {
    return dispatch({
        type: types.EVENT_RELATIONSHIP_REMOVED,
    })
};