import Immerable from "../Immerable";
import Individual from "../Individual/Individual";
import IndividualFormDto from "./IndividualFormDto";

export default class RelationshipFormDto extends Immerable {
    id;
    individual;
    partnerForRelationship;

    constructor(id: number | null, individual: IndividualFormDto, partnerForRelationship: IndividualFormDto) {
        super();

        this.id = id;
        this.individual = individual;
        this.partnerForRelationship = partnerForRelationship;


    }

    static createNewForIndividual(individual: Individual) {
        return new RelationshipFormDto(
            null,
            IndividualFormDto.createFromIndividual(individual),
            IndividualFormDto.new()
        );
    }

}








