import React, {useEffect, useState} from "react";
import {Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Family from "../../models/Family/Family";

interface FamilyListDto {
    families: []
}

const FamiliesList = ({families}: FamilyListDto) => {

    const [query, setQuery] = useState('');
    const [filteredFamilyNames, setFilteredFamilyNames] = useState<Array<Family>>(families);

    useEffect(() => {
        setFilteredFamilyNames(families)
    }, [families]);

    const onChange = (event: any) => {
        setQuery(event.target.value.toLowerCase());
        filterList();
    };

    const filterList = () => {
        const filteredFamilies = families.filter((family: Family) => {
            return family.name.toLowerCase().indexOf(query) != -1; // returns true or false
        });
        setFilteredFamilyNames(filteredFamilies);
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon3">
                                Suche nach:
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" placeholder="Familiennamen" value={query} onChange={onChange}/>
                    </InputGroup>

                </Col>
            </Row>

            <Row>
                <Col>
                    <ul className="list-columns">
                        {filteredFamilyNames.map((family: Family, key: number) => {
                            return <li key={key}>{family.name} ({family.count})</li>;
                        })}
                    </ul>
                </Col>
            </Row>
        </React.Fragment>


    )
};

export default FamiliesList;