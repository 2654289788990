import React, {useState} from "react";
import FormControlButtons from "./component/FormControlButtons";
import {getIndividual, saveRelationshipChild} from "./reducer";
import IndividualForm from "./forms/IndividualForm";
import IndividualFormDto from "../../models/Form/IndividualFormDto";
import Individual from "../../models/Individual/Individual";
import IndividualSelect from "./component/IndividualSelect";
import {useDispatch} from "react-redux";
import RelationshipChildFormDto from "../../models/Form/RelationshipChildFormDto";
import {eventRelationshipChildSaved} from "../../components/eventBus/reducer";


interface FormProps {
    relationshipChild: RelationshipChildFormDto,
    setShow?: any
}

const RelationshipChildFormContainer = ({relationshipChild, setShow}: FormProps) => {
    const [child, setChild] = useState<any>(relationshipChild.child);
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setChild((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSave = () => {
        saveRelationshipChild(relationshipChild.relationshipId, child).then(() => {
            dispatch(eventRelationshipChildSaved());
            setShow(false)
        });
    };

    const onCancel = () => {
        setChild(child);
        setShow(false);
    };

    const onSelect = (individualId: number) => {
        getIndividual(individualId).then((response: any) => {
            const child = Individual.createFromBackend(response);
            setChild(IndividualFormDto.createFromIndividual(child));
        });

    };

    const disabledUntil = () => {
        return child.firstName == "" || child.lastName == ""
    };


    return (
        <React.Fragment>

            <IndividualSelect onSelect={onSelect}/>

            <hr/>

            <IndividualForm form={child} handleChange={handleChange}/>

            <FormControlButtons onSave={onSave} onCancel={onCancel} saveDisabled={disabledUntil()}/>

        </React.Fragment>
    )
};


export default RelationshipChildFormContainer;
