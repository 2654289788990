import React from "react";
import {Col, Row} from "react-bootstrap";
import EventNote from "./EventNote";
import EventName from "./EventName";
import EventPlace from "./EventPlace";
import EventText from "./EventText";
import EventDate from "./EventDate";
import EventSource from "./EventSource";
import Secured from "../../../security/Secured";
import FormModal from "../../../form/component/FormModal";
import EventHelper from "../../../../services/EventHelper";
import IndividualEventFormDto from "../../../../models/Form/IndividualEventFormDto";

const EventRow = ({event, connectWith}: any) => {

    const eventFormDto = IndividualEventFormDto.createFromEvent(event, connectWith);

    return (
        <Row className="border border-light event-row">
            <Col xs={12} sm={3} className="bg-secondary">
                <Row className="event-info">
                    <EventName event={event}/>
                    {event.note && <EventNote event={event}/>}
                    {event.source && <EventSource event={event}/>}
                </Row>
            </Col>
            <Col className="event-details">
                {event.date && <EventDate event={event}/>}
                {event.place && <EventPlace event={event}/>}
                {event.detail && <EventText event={event}/>}
            </Col>
            <Secured>
                <Col xs={2} lg={1} className="col-left-border align-center edit-col">
                    <FormModal title={EventHelper.getReadableEventName(event.name) + " bearbeiten"}
                               createFormFrom={eventFormDto}>
                        <i className="fa fa-pen"/>
                    </FormModal>
                </Col>
            </Secured>
        </Row>
    )
};


export default EventRow;
