import {produce} from "immer";
import {toast} from "react-toastify";
import Individual from "../../models/Individual/Individual";
import SearchClient from "../../backend/SearchClient";

export const types = {
    INIT: "PLACES/INIT",
    FETCH_SEARCH_RESULTS: "SEARCH/FETCH_SEARCH_RESULTS",
    FETCH_SEARCH_RESULTS_SUCCESS: "SEARCH/FETCH_SEARCH_RESULTS_SUCCESS",
    RESET_SEARCH_RESULTS: "SEARCH/RESET_SEARCH_RESULTS"
};

export const initialState = {
    count: 0,
    results: [],
    isSearching: false
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.FETCH_SEARCH_RESULTS:
            draft.isSearching = true;
            return draft;
        case types.FETCH_SEARCH_RESULTS_SUCCESS:
            draft.results = action.individuals?.map(individual => Individual.createFromBackend(individual)) ?? [];
            draft.count = action.count || 0;
            draft.isSearching = false;
            return draft;
        case types.RESET_SEARCH_RESULTS:
            draft.results = [];
            draft.count = 0;
            draft.isSearching = false;
    }
    return draft;
}, initialState);

export const fetchSearchResults = (queryParams) => dispatch => {
    dispatch({type: types.RESET_SEARCH_RESULTS});
    dispatch({type: types.FETCH_SEARCH_RESULTS});
    const searchClient = new SearchClient();

    return searchClient.search(queryParams)
        .then(response => {
            console.log(response.data.count)
            return response.data
        })
        .then(data => dispatch({
            type: types.FETCH_SEARCH_RESULTS_SUCCESS,
            individuals: data.data,
            count: data.count
        }))
        .catch(error => {
            toast.error(`Fehler beim Abruf der Orte: ${error}`);
            console.log(error);
        });
};

export const resetSearchResults = () => dispatch => {
    dispatch({
        type: types.RESET_SEARCH_RESULTS
    })
};