import React from "react";
import Title from "../../../components/title/Title";

const FamilySatistics = () => {

    return (
        <React.Fragment>
            <Title title="Statistik :: Familien" />
        </React.Fragment>
    )
};

export default FamilySatistics;