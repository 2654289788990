import React, {useEffect} from "react";
import Title from "../../components/title/Title";
import {useDispatch, useSelector} from "react-redux";
import {fetchPlaces} from "./reducer";
import {Col, Row} from "react-bootstrap";
import LeafletMap from "./LeafletMap";
import PlacesList from "./PlacesList";

const Places = () => {

    const dispatch = useDispatch();
    const {list} = useSelector((state: any) => state.places);

    useEffect(() => {
        dispatch(fetchPlaces());
    }, []);

    return (
        <React.Fragment>
            <Title title="Orte"/>

            <Row>
                <Col sm={12} md={6}>
                    <p>
                        In der folgenden Liste sind alle Orte die im Bezug auf die Ahnenforschung aufgetreten sind
                        aufgelistet. Ein Klick auf den Ortsnamen zeigt die genaue Lage des Ortes auf der Karte an.
                    </p>

                    <LeafletMap places={list}/>
                </Col>
                <Col sm={12} md={6}>
                    <PlacesList places={list}/>
                </Col>
            </Row>

        </React.Fragment>
    )
};

export default Places;