import React from "react";
import BranchItemEvents from "./BranchItemEvents";
import {useHistory} from "react-router";

const BranchItem = ({individual}: any) => {

    const history = useHistory();

    const onClick = () => {
        history.push("/individual/" + individual.id)
    };

    return (
        <span className={"label bg-" + individual.sex} onClick={() => onClick()}>
            {individual.firstName} {individual.lastName}<br/>
            <BranchItemEvents individualEvents={individual.events}/>
        </span>

    )
};


export default BranchItem;
