import React, {useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import EventRow from "../event/EventRow";
import Event from "../../../../models/Event/Event";
import Secured from "../../../security/Secured";
import FormModal from "../../../form/component/FormModal";
import RelationshipEventFormDto from "../../../../models/Form/RelationshipEventFormDto";
import IndividualRelationship from "../../../../models/Individual/IndividualRelationship";
import IndividualParentsRelationship from "../../../../models/Individual/IndividualParentsRelationship";

const RelationshipEventsCard = ({relationship}: { relationship: IndividualRelationship | IndividualParentsRelationship}) => {

    const [toggle, setToggle] = useState(false);

    return (
        <Card bg="dark" border="light">
            <Card.Header className="">
                <Row>
                    <Col>
                        Events dieser Beziehung
                    </Col>

                    <Col xs={2} lg={1} className="col-left-border align-center" onClick={() => setToggle(!toggle)}>
                        <div>
                            {toggle ? <i className="fas fa-chevron-circle-up"/> :
                                <i className="fas fa-chevron-circle-down"/>}
                        </div>
                    </Col>
                </Row>
            </Card.Header>

            <div className={toggle ? "collapsed show" : "collapsed"}>
                {relationship.events && relationship.events.map((event: Event) => {
                    return <EventRow key={event.id} event={event} connectWith={relationship.id}/>;
                })}
                <Secured>
                    <FormModal title={'Neues Event'} createFormFrom={RelationshipEventFormDto.new(relationship.id)}>
                        <button className="add-button">Event hinzufügen</button>
                    </FormModal>
                </Secured>
            </div>
        </Card>
    )
};

export default RelationshipEventsCard;
