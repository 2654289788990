import React, {useEffect, useState} from "react";
import Title from "../../components/title/Title";
import {useDispatch, useSelector} from "react-redux";
import {fetchFamilies} from "./reducer";
import FamiliesList from "./FamiliesList";

const Families = () => {

    const dispatch = useDispatch();
    const {list} = useSelector((state: any) => state.families);

    useEffect(() => {
        dispatch(fetchFamilies());
    }, []);

    return (
        <React.Fragment>
            <Title title="Familien"/>

            <FamiliesList families={list}/>
        </React.Fragment>
    )
};

export default Families;