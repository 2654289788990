import React from "react";
import img from "../../assets/header_bg.png";

const HeaderTop = () => {

    return (
        <div className="container-fluid header" style={{backgroundImage: "url(" + img + ")"}}>
            <div className="headline">
                <h1>Genealogie <span>Familien Wurster / Rist</span></h1>
            </div>

        </div>
    )
};

export default HeaderTop;