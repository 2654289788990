import React from "react";
import BranchItem from "./BranchItem";

const DescendantBranch = ({partners, level}: any) => {
    if (!partners || partners.length === 0) {
        return (<></>);
    }

    return (
        <div className={"branch lv" + level}>
            {partners.map((partner: any) => {
                return partner.children && partner.children.map((child: any) => {
                    return (
                        <div className={partner.children.length === 1 ? "entry sole" : "entry"} key={child.id}>
                            <BranchItem individual={child}/>
                            <DescendantBranch partners={child.partners} level={level + 1}/>
                        </div>
                    );
                })
            })}
        </div>
    )
};


export default DescendantBranch;
