import Immerable from "../Immerable";
import Date from '../Date/Date';
import Place from '../Place/Place';

export default class Event extends Immerable {
    id;
    date;
    eventOccurred;
    name;
    note;
    place;
    source;
    detail;


    constructor(id: number, date: Date, eventOccurred: boolean, name: string, note: string, place: string, source: string, detail: string) {
        super();

        this.id = id;
        this.date = date && Date.createFromBackend(date);
        this.eventOccurred = eventOccurred;
        this.name = name;
        this.note = note;
        this.place = place && Place.createFromBackend(place);
        this.source = source;
        this.detail = detail;

    }

    static createFromBackend(json: any) {
        return new Event(
            json.id,
            json.date,
            json.eventOccurred,
            json.name,
            json.note,
            json.place,
            json.source,
            json.detail,
        );
    }
}








