import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import IndividualEventFormDto from "../../../models/Form/IndividualEventFormDto";
import IndividualEventFormContainer from "../IndividualEventFormContainer";
import IndividualFormContainer from "../IndividualFormContainer";
import IndividualFormDto from "../../../models/Form/IndividualFormDto";
import RelationshipFormDto from "../../../models/Form/RelationshipFormDto";
import RelationshipFormContainer from "../RelationshipFormContainer";
import RelationshipChildFormDto from "../../../models/Form/RelationshipChildFormDto";
import RelationshipChildFormContainer from "../RelationshipChildFormContainer";
import FormControlButtons from "./FormControlButtons";

const ConfirmModal = ({title, onSave, children}: any) => {

    const [show, setShow] = useState(false);

    return (
        <React.Fragment>

            <div onClick={() => {setShow(!show);}}>
                {children}
            </div>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title ? title : 'TITEL VERGESSEN'}</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <FormControlButtons onSave={() => {onSave();setShow(false)}} onCancel={() => setShow(false)}/>
                </Modal.Footer>
            </Modal>


        </React.Fragment>
    )
};


export default ConfirmModal;
