import React, {useEffect} from "react";
import {Redirect, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logoutUser} from "./reducer";

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutUser());
    }, []);

    const history = useHistory();
        history.goBack()

    return (
        <>.</>
    );

    // return (
    //     <Redirect to="/"/>
    // );
};

export default Logout;