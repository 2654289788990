import React from "react";
import {useSelector} from "react-redux";
import jwt from "jsonwebtoken";
import User from "../../models/User/User";


interface SecuredProps {
    children: React.ReactNode
}

const Secured = ({children}: SecuredProps) => {

    const {user} = useSelector((state: any) => state.security);

    let isAdmin = false;
    if (user && user.authToken) {
        const decodedUser = User.createFromState(jwt.decode(user.authToken));
        decodedUser.roles.map((role: any) => {
             if(role === "ROLE_ADMIN"){
                 isAdmin = true;
             }
        })
    }

    return (
        <React.Fragment>
            {isAdmin ? children : null}
        </React.Fragment>
    );
};

export default Secured;