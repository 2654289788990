import React, {useState} from "react";
import FormControlButtons from "./component/FormControlButtons";
import {getIndividual, saveRelationship} from "./reducer";
import IndividualForm from "./forms/IndividualForm";
import IndividualFormDto from "../../models/Form/IndividualFormDto";
import RelationshipFormDto from "../../models/Form/RelationshipFormDto";
import Individual from "../../models/Individual/Individual";
import IndividualSelect from "./component/IndividualSelect";
import {eventRelationshipSaved} from "../../components/eventBus/reducer";
import {useDispatch} from "react-redux";


interface FormProps {
    relationship: RelationshipFormDto,
    setShow?: any
}

const RelationshipFormContainer = ({relationship, setShow}: FormProps) => {
    const [partner, setPartner] = useState<any>(relationship.partnerForRelationship);
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setPartner((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSave = () => {
        saveRelationship(relationship.individual, partner).then(() => {
            dispatch(eventRelationshipSaved());
            setShow(false)
        });
    };

    const onCancel = () => {
        setPartner(partner);
        setShow(false);
    };

    const onSelect = (individualId: number) => {
        getIndividual(individualId).then((response: any) => {
            const partner = Individual.createFromBackend(response);
            setPartner(IndividualFormDto.createFromIndividual(partner));
        });

    };

    const disabledUntil = () => {
        return partner.firstName == "" || partner.lastName == ""
    };


    return (
        <React.Fragment>

            <IndividualSelect onSelect={onSelect}/>

            <hr/>

            <IndividualForm form={partner} handleChange={handleChange}/>

            <FormControlButtons onSave={onSave} onCancel={onCancel} saveDisabled={disabledUntil()}/>

        </React.Fragment>
    )
};


export default RelationshipFormContainer;
