import React, {useState} from "react";
import {Card} from "react-bootstrap";
import CardHeader from "./CardHeader";
import EventRow from "../event/EventRow";
import Event from "../../../../models/Event/Event";
import Individual from "../../../../models/Individual/Individual";
import Secured from "../../../security/Secured";
import FormModal from "../../../form/component/FormModal";
import IndividualEventFormDto from "../../../../models/Form/IndividualEventFormDto";

const IndividualCard = ({individual, open}: { individual: Individual | any, open?: boolean | null }) => {

    const [toggle, setToggle] = useState(open);

    return (
        <Card bg="dark" border="light">
            <CardHeader individual={individual} toggle={toggle} setToggle={setToggle}/>

            <div className={toggle ? "collapsed show" : "collapsed"}>
                {individual.events && individual.events.map((event: Event) => {
                    return <EventRow key={event.id} event={event} connectWith={individual.id}/>;
                })}
                <Secured>
                    <FormModal title={'Neues Event'} createFormFrom={IndividualEventFormDto.new(individual.id)}>
                        <button className="add-button">Event hinzufügen</button>

                    </FormModal>
                </Secured>
            </div>
        </Card>
    )
};

export default IndividualCard;
