import React from "react";
import Title from "../../components/title/Title";
import {Col, Row} from "react-bootstrap";

const Home = () => {

    return (
        <React.Fragment>
            <Title title="Willkommen"/>

            <Row>
                <Col>&nbsp;</Col>
            </Row>

            {/* todo: remove when finished*/}
            {/*   --->   */}
            <Row>
                <Col xs={1} sm={1} lg={2} xl={3}/>
                <Col className="status">
                    AKTUELLER STATUS:
                    <span className="status poc">  Proof of concept </span> &rarr;
                    <span className="status wip">  Work in progress </span> &rarr;
                    <span className="status beta">  Testphase </span> &rarr;
                    <span className="status ready">  Fertigstellung </span>
                </Col>
                <Col xs={1} sm={1} lg={2} xl={3}/>
            </Row>

            <Row>
                <Col>&nbsp;</Col>
            </Row>
            {/*   <---   */}

            <Row>
                <Col xs={1} sm={1} lg={2} xl={3}/>
                <Col>
                    <p>
                        Diese Seite befasst sich mit der Ahnenforschung der
                        Familien <em>WURSTER</em> und <em>RIST</em> mit den
                        Familien <em>BOHNENBERGER</em> und <em>KALMBACH</em> (Kreis Calw, Baden-Württemberg).
                    </p>
                    <p>
                        Die Inhalte auf dieser Seite spiegeln (in leicht reduziertem Umfang) das Ergebnis der
                        Ahnenforschung zu diesen Familien wieder, die meine Eltern und ich im Laufe der Jahre
                        zusammengetragen haben.
                    </p>
                </Col>
                <Col xs={1} sm={1} lg={2} xl={3}/>
            </Row>

            <Row>
                <Col xs={1} sm={1} lg={2} xl={3}/>
                <Col>
                    <p className="text-right">
                        Michael Wurster
                    </p>
                </Col>
                <Col xs={1} sm={1} lg={2} xl={3}/>
            </Row>


            <Row>
                <Col xs={1} sm={1} lg={2} xl={3}/>
                <Col className="text-center">
                    <hr/>
                    <blockquote cite="https://www.imdb.com/title/tt0062622/quotes/qt0396921">
                        Wer im Gedächtnis seiner Lieben lebt,<br/>
                        der ist nicht tot, er ist nur fern;<br/>
                        tot ist - wer vergessen ist.
                    </blockquote>
                    <figcaption>
                        &mdash; <cite>Eduard Möricke</cite>
                    </figcaption>

                </Col>
                <Col xs={1} sm={1} lg={2} xl={3}/>
            </Row>

        </React.Fragment>
    )
};

export default Home;