import React, {useState} from "react";
import FormControlButtons from "./component/FormControlButtons";
import {getIndividual, saveParents, saveRelationship} from "./reducer";
import IndividualForm from "./forms/IndividualForm";
import IndividualFormDto from "../../models/Form/IndividualFormDto";
import RelationshipFormDto from "../../models/Form/RelationshipFormDto";
import Individual from "../../models/Individual/Individual";
import IndividualSelect from "./component/IndividualSelect";
import {eventRelationshipSaved} from "../../components/eventBus/reducer";
import {useDispatch} from "react-redux";
import ParentsFormDto from "../../models/Form/ParentsFormDto";
import FormModal from "./component/FormModal";


interface FormProps {
    parents: ParentsFormDto,
    setShow?: any
}

const ParentsFormContainer = ({parents, setShow}: FormProps) => {
    const [parent1, setParent1] = useState<any>(parents.parent1);
    const [parent2, setParent2] = useState<any>(parents.parent2);
    const dispatch = useDispatch();


    const onSave = () => {
        saveParents(parent1, parent2, parents.individual).then(() => {
            dispatch(eventRelationshipSaved());
            setShow(false)
        });
    };

    const onCancel = () => {
        setParent1(parents.parent1);
        setParent2(parents.parent2);
        setShow(false);
    };

    const onSelectParent1 = (individualId: number) => {
        getIndividual(individualId).then((response: any) => {
            const partner = Individual.createFromBackend(response);
            setParent1(IndividualFormDto.createFromIndividual(partner));
        });
    };

    const onSelectParent2 = (individualId: number) => {
        getIndividual(individualId).then((response: any) => {
            const partner = Individual.createFromBackend(response);
            setParent2(IndividualFormDto.createFromIndividual(partner));
        });
    };

    const disabledUntil = () => {
        return parent1.firstName == "" || parent2.firstName == ""
    };


    return (
        <React.Fragment>

            Partner 1
            <IndividualSelect onSelect={onSelectParent1}/>

            <hr/>

            Partner 2
            <IndividualSelect onSelect={onSelectParent2}/>

            <hr/>

            <FormModal title="Neue Person" createFormFrom={IndividualFormDto.new()}>
                <button className="add-button button-small mb-2"><i className="fas fa-plus"/> Person hinzufügen</button>
            </FormModal>


            <FormControlButtons onSave={onSave} onCancel={onCancel} saveDisabled={disabledUntil()}/>

        </React.Fragment>
    )
};


export default ParentsFormContainer;
