import React from "react";
import {Col, Row} from "react-bootstrap";
import IndividualCard from "../components/individualcard/IndividualCard";
import Title from "../../../components/title/Title";
import Secured from "../../security/Secured";
import FormModal from "../../form/component/FormModal";
import ColHeader from "../components/header/ColHeader";
import IndividualHelmet from "../components/helmet/IndividualHelmet";
import ParentsFormDto from "../../../models/Form/ParentsFormDto";
import ChildUnlinkButton from "../../form/component/ChildUnlinkButton";
import RelationshipChildFormDto from "../../../models/Form/RelationshipChildFormDto";
import RelationshipUnlinkButton from "../../form/component/RelationshipUnlinkButton";
import Individual from "../../../models/Individual/Individual";
import IndividualParentsRelationship from "../../../models/Individual/IndividualParentsRelationship";
import RelationshipEventsCard from "../components/relationshipeventcard/RelationshipEventsCard";

interface TabProps {
    individual: Individual,
    parents: IndividualParentsRelationship
}

const TabParents = ({individual, parents}: TabProps) => {

    const onSave = (something: any) => {
        console.log(something)
    }

    return (
        <React.Fragment>
            <IndividualHelmet prefix={"Familie von"} individual={individual}/>

            <Row>
                <Col sm={12} md={6} xl={6}>
                    <Title title={"Familie von"} subtitle={individual.firstName + " " + individual.lastName}/>
                </Col>
                <Col sm={12} md={6} xl={6}>
                    <IndividualCard individual={individual}/>
                </Col>
                <Col sm={12} md={6} xl={6}>
                    &nbsp;
                </Col>
            </Row>

            <hr/>

            <Row>
                <Col sm={12} md={6} xl={6} className="parents-col">
                    <ColHeader text="Eltern" className="parents"/>
                    {parents
                        ? <React.Fragment>

                            <Secured>
                                <RelationshipUnlinkButton relationshipId={parents.id}/>
                            </Secured>

                            <IndividualCard individual={parents.partner1}/>
                            <IndividualCard individual={parents.partner2}/>
                            <RelationshipEventsCard relationship={parents}/>

                        </React.Fragment>
                        : <Secured>
                            <FormModal title={'Eltern hinzufügen'}
                                       createFormFrom={ParentsFormDto.createNewForIndividual(individual)}>
                                <button className="add-button">Eltern hinzufügen</button>
                            </FormModal>
                        </Secured>
                    }
                </Col>
                <Col sm={12} md={6} xl={6}>
                    <ColHeader text="Geschwister" className="siblings"/>
                    {parents && parents.siblings ?
                        parents.siblings.map((sibling: any) => {
                            return (
                                <div className="unlink-box" key={sibling.id}>
                                    <IndividualCard individual={sibling} key={sibling.id}/>
                                    <Secured>
                                        <ChildUnlinkButton childId={sibling.id}/>
                                    </Secured>
                                </div>
                            );
                        })
                        :
                        null
                    }

                    {parents &&
                    <Secured>
                        <FormModal title={'Geschwister hinzufügen'}
                                   createFormFrom={RelationshipChildFormDto.createNewForParentsRelationship(parents)}>
                            <button className="add-button">Geschwister hinzufügen</button>
                        </FormModal>
                    </Secured>
                    }

                </Col>
                <Col sm={12} md={6} xl={6}>
                    &nbsp;
                </Col>
            </Row>


        </React.Fragment>
    )
};


export default TabParents;
