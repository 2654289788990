import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import DateFormatter from "../../../../services/DateFormatter";
import Secured from "../../../security/Secured";
import FormModal from "../../../form/component/FormModal";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchIndividual} from "../../reducer";
import IndividualFormDto from "../../../../models/Form/IndividualFormDto";
import IndividualHelper from "../../../../services/IndividualHelper";

const CardHeader = ({individual, toggle, setToggle}: any) => {

    const modalTitle = individual.firstName + ' ' + individual.lastName + ' bearbeiten';

    const history = useHistory();
    const dispatch = useDispatch();
    const onClick = () => {
        history.push('/individual/' + individual.id);
        dispatch(fetchIndividual(individual.id));
    };

    return (
        <Card.Header className={"bg-" + individual.sex}>
            <Row>
                <Secured>
                    <Col xs={1} className="my-auto">
                        <FormModal title={modalTitle}
                                   createFormFrom={IndividualFormDto.createFromIndividual(individual)}>
                            <i className="fa fa-pen"/>
                        </FormModal>
                    </Col>
                </Secured>

                <Col onClick={() => onClick()}>
                    <Row>
                        <Col className="card-name">
                            {IndividualHelper.getFullName(individual)}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="card-dates">
                            <small>{DateFormatter.createReadableLifespanDate(individual)}</small>
                        </Col>
                    </Row>
                </Col>

                <Col xs={2} lg={1} className="col-left-border align-center" onClick={() => setToggle(!toggle)}>
                    <div>
                        {toggle ? <i className="fas fa-chevron-circle-up"/> :
                            <i className="fas fa-chevron-circle-down"/>}
                    </div>
                </Col>


            </Row>

        </Card.Header>
    )
};


export default CardHeader;
