import Immerable from "../Immerable";

export default class Family extends Immerable {
    name;
    count;

    constructor(name, count) {
        super();

        this.name = name;
        this.count = count;
    }

    static createFromBackend(json) {
        return new Family(
            json.name,
            json.count
        );
    }
}