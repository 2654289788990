import Immerable from "../Immerable";

export default class User extends Immerable {
    roles;
    authToken;
    isAuthenticated;

    constructor(roles: Array<string>, authToken: string, isAuthenticated: boolean) {
        super();

        this.roles = roles;
        this.authToken = authToken;
        this.isAuthenticated = isAuthenticated;
    }

    static createFromState(json:any) {
        return new User(
            json.roles,
            json.authToken,
            json.isAuthenticated
        );
    }
}