import React from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import {NavLink} from "react-router-dom";
import DateFormatter from "../../../services/DateFormatter";
import Secured from "../../security/Secured";

export const tableConfig = [
    {
        dataField: 'sex',
        text: ' ',
        classes: (cell: any, row: any, rowIndex: any, colIndex: any) => {
            return cell === "M" ? 'bg-M' : cell === "F" ? 'bg-F' : '';
        },
        formatter: (value: any, row: any) => (
                <span>
                    <Secured>[ {row.id} ]</Secured>
                    {value === "M" ? <i className="fas fa-mars"/> :
                        value === "F" ? <i className="fas fa-venus"/> :
                        <i className="fas fa-genderless"/>}
                </span>
        ),
        sort: true
    }, {
        dataField: 'firstName',
        text: 'Vorname',
        sort: true,
        formatter: (value: any, row: any) => (
            <NavLink className="nav-link" to={"/individual/" + row.id} exact={true}>
                {value}
            </NavLink>
        )
    }, {
        dataField: 'lastName',
        text: 'Nachname',
        sort: true,
        formatter: (value: any, row: any) => (
            <NavLink className="nav-link" to={"/individual/" + row.id} exact={true}>
                {value}
            </NavLink>
        )
    }, {
        dataField: 'birthdate.dateMin',
        text: 'Geburtsdatum',
        sort: true,
        formatter: (value: any, row: any) => (
            <span>{DateFormatter.readableDateFormatter(row.birthdate)}</span>
        )
    }, {
        dataField: 'birthplace',
        text: 'Geburtsort',
        sort: true
    }, {
        dataField: 'deathdate.dateMin',
        text: 'Todesdatum',
        sort: true,
        formatter: (value: any, row: any) => (
            <span>{DateFormatter.readableDateFormatter(row.deathdate)}</span>
        )
    }, {
        dataField: 'deathplace',
        text: 'Todesort',
        sort: true
    }, {
        dataField: 'age',
        text: 'Alter',
        sort: true,
        formatter: (value: any, row: any) => (
            <span>
            {row.birthdateAccuracy || row.deathdateAccuracy ? "etwa " : null}{value}
        </span>
        )
    }
];

export const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    paginationSize: 3,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: false,
    alwaysShowAllBtns: false,
});