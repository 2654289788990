import React, {useEffect} from "react";
import Select from 'react-select'
import {useDispatch, useSelector} from "react-redux";
import DateFormatter from "../../../services/DateFormatter";
import ListIndividual from "../../../models/Individual/ListIndividual";
import {fetchListIndividuals} from "../../individuals/reducer";

const IndividualSelect = ({onSelect}: any) => {

    const {list} = useSelector((state: any) => state.individuals);
    const {individualEvent} = useSelector((state: any) => state.events);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchListIndividuals());
    }, [individualEvent]);

    const options = list?.map((individual: ListIndividual) => {
        return {
            sex: individual.sex,
            value: individual.id,
            label: individual.firstName
                + " "
                + individual.lastName
                + " ("
                + DateFormatter.readableDateFormatter(individual.birthdate)
                + " - "
                + DateFormatter.readableDateFormatter(individual.birthdate)
                + ")"
        };
    });

    const customStyles = {
        option: (provided: any, state: any) => (
            {
                ...provided,
                borderBottom: '1px dotted white',
                color: 'white',
                background: state.data.sex === "M" ? '#2A4C66;' : state.data.sex === "F" ? '#5D2E33;' : '#343a40;',
                padding: 5,
            }
        ),
        control: (provided: any, state: any) => (
            {
                ...provided,
                background: '#343a40',
                border: 'none',
                borderBottom: '1px solid white',
                borderRadius: 0,
            }
        ),
        input: (provided: any, state: any) => (
            {
                ...provided,
                color: 'white !important'
            }
        ),
        singleValue: (provided: any, state: any) => (
            {
                ...provided,
                color: 'white !important'
            }
        )
    }

    return (
        <React.Fragment>
            <Select options={options} styles={customStyles} onChange={(e:any) => onSelect(e.value)} placeholder={"Auswählen oder neu erfassen"} />
        </React.Fragment>
    );
};


export default IndividualSelect;
