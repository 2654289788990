import Immerable from "../Immerable";
import Individual from "../Individual/Individual";
import IndividualFormDto from "./IndividualFormDto";

export default class ParentsFormDto extends Immerable {
    id;
    parent1;
    parent2;
    individual;

    constructor(id: number | null, parent1: IndividualFormDto, parent2: IndividualFormDto, individual: IndividualFormDto) {
        super();

        this.id = id;
        this.parent1 = parent1;
        this.parent2 = parent2;
        this.individual = individual;


    }

    static createNewForIndividual(individual: Individual) {
        return new ParentsFormDto(
            null,
            IndividualFormDto.new(),
            IndividualFormDto.new(),
            IndividualFormDto.createFromIndividual(individual)

        );
    }

}








