import createInstance from "./AxiosFactory";
import Config from "../Config";
import BACKEND_ROUTES from "./BACKEND_ROUTES";

class FormClient {
    constructor() {
        this.apiUrl = Config.BACKEND_API;
        this.axios = createInstance();
    }

    saveIndividualEvent(form) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_INDIVIDUAL_EVENT_SAVE,
            {form: form}
        );
    }

    saveRelationshipEvent(form) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_RELATIONSHIP_EVENT_SAVE,
            {form: form}
        );
    }

    deleteIndividualEvent(eventId) {
        return this.axios.delete(
            this.apiUrl + BACKEND_ROUTES.FORM_INDIVIDUAL_EVENT_DELETE,
            {data: {id: eventId}}
        );
    }

    saveIndividual(form) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_INDIVIDUAL_SAVE,
            {form: form}
        );
    }

    saveRelationship(individual, partner) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_RELATIONSHIP_SAVE,
            {form: {individual, partner}}
        );
    }

    saveRelationshipChild(relationshipId, child) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_RELATIONSHIP_CHILD_SAVE,
            {form: {relationshipId, child}}
        );
    }

    removeRelationshipChild(childId) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_RELATIONSHIP_CHILD_REMOVE,
            {form: {childId}}
        );
    }

    removeRelationship(relationshipId) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_RELATIONSHIP_REMOVE,
            {form: {relationshipId}}
        );
    }

    saveParents(parent1, parent2, individual) {
        return this.axios.post(
            this.apiUrl + BACKEND_ROUTES.FORM_INDIVIDUAL_PARENTS_SAVE, {form: {parent1, parent2, individual}}
        );
    }
}

export default FormClient;