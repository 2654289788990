import React from "react";
import {Col, Form} from "react-bootstrap";
import EventHelper from "../../../services/EventHelper";


interface FormProps {
    queryParams: any,
    handleChange: any,
    handleKeyPress: any
}

const SearchForm = ({queryParams, handleChange, handleKeyPress}: FormProps) => {

    return (
        <React.Fragment>

            <Form.Group controlId="searchFirstName">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Vorname</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control type="text" className=""
                                      placeholder=""
                                      name="firstName"
                                      value={queryParams.firstName}
                                      onChange={(e: any) => handleChange(e)}
                                      onKeyPress={handleKeyPress}/>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="searchLastName">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Nachname</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control type="text"
                                      placeholder=""
                                      name="lastName"
                                      value={queryParams.lastName}
                                      onChange={(e: any) => handleChange(e)}
                                      onKeyPress={handleKeyPress}/>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="searchPlace">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Ort</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control type="text"
                                      placeholder=""
                                      name="place"
                                      value={queryParams.place}
                                      onChange={(e: any) => handleChange(e)}
                                      onKeyPress={handleKeyPress}/>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="searchEvent">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Ereignis</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control as="select" onChange={(e: any) => handleChange(e)}
                                      name="event"
                                      value={queryParams.event ? queryParams.event : ''}>
                            <option value="" disabled>Wählen</option>
                            <option value="" disabled>───────────────────</option>
                            {EventHelper.getEvents().map((name: string) => {
                                return (
                                    <option value={name} key={name}>{EventHelper.getReadableEventName(name)}</option>
                                );
                            })}
                        </Form.Control>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="searchDate">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Datum</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control type="text"
                                      placeholder="dd.mm.yyyy / mm.yyyy / yyyy"
                                      name="date"
                                      value={queryParams.date}
                                      onChange={(e: any) => handleChange(e)}
                                      onKeyPress={handleKeyPress}/>
                    </Col>
                </Form.Row>
            </Form.Group>

            <hr/>

            <Form.Group controlId="sort">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Sortieren nach</Form.Label>
                    </Col>
                    <Col xs={4}>
                        <Form.Control as="select" onChange={(e: any) => handleChange(e)}
                                      name="sort"
                                      value={queryParams.sort ? queryParams.sort : ''}>
                            <option value="firstName">Nachname</option>
                            <option value="lastName">Vorname</option>
                            <option value="place">Ort</option>
                            <option value="date">Datum</option>
                        </Form.Control>
                    </Col>


                    <Col xs={2} className="align-center text-center">
                        <i className="fas fa-male"/>&nbsp;
                        <Form.Check
                            type="radio"
                            id="sortOrderAsc"
                            name="sortOrder"
                            defaultValue="asc"
                            checked={queryParams.sortOrder == "asc"}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                    <Col xs={2} className="align-center">
                        <i className="fas fa-female"/>&nbsp;
                        <Form.Check
                            type="radio"
                            id="sortOrderDesc"
                            name="sortOrder"
                            defaultValue="desc"
                            checked={queryParams.sortOrder == "desc"}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>


                </Form.Row>
            </Form.Group>


        </React.Fragment>
    )
};


export default SearchForm;
