import React from "react";
import Event from "../../../../models/Event/Event";
import {Col, Row} from "react-bootstrap";

interface EventPlaceProps {
    event: Event,
}

const EventPlace = ({event}: EventPlaceProps) => {

    return (
        <Row>
            <Col xs={1}>
                <i className="fa fa-map-marked-alt"/>
            </Col>
            <Col xs={10}>
                {event.place ? event.place.name : ""}
            </Col>
        </Row>
    )
};


export default EventPlace;
