import React from "react";
import {Col} from "react-bootstrap";

const EventSource = ({event}: any) => {

    return (
        <Col xs={2}>
            <i className="fa fa-file-export"/>
        </Col>
    )
};


export default EventSource;
