import Immerable from "../Immerable";
import Individual from "../Individual/Individual";
import IndividualFormDto from "./IndividualFormDto";
import IndividualRelationship from "../Individual/IndividualRelationship";
import IndividualParentsRelationship from "../Individual/IndividualParentsRelationship";

export default class RelationshipChildFormDto extends Immerable {
    relationshipId;
    child;

    constructor(relationshipId: number, child: IndividualFormDto) {
        super();

        this.relationshipId = relationshipId;
        this.child = child;
    }

    static createNewForRelationship(relationship: IndividualRelationship) {
        return new RelationshipChildFormDto(
            relationship.id,
            IndividualFormDto.new()
        );
    }

    static createNewForParentsRelationship(parentsRelationship: IndividualParentsRelationship) {
        return new RelationshipChildFormDto(
            parentsRelationship.id,
            IndividualFormDto.new()
        );
    }

}








