import IndividualClient from "../../backend/IndividualClient";
import {produce} from "immer";
import {toast} from "react-toastify";
import Individual from "../../models/Individual/Individual";
import IndividualRelationship from "../../models/Individual/IndividualRelationship";
import IndividualParentsRelationship from "../../models/Individual/IndividualParentsRelationship";
import IndividualAncestors from "../../models/Individual/IndividualAncestors";
import IndividualDescendants from "../../models/Individual/IndividualDescendants";

export const types = {
    INIT: "INDIVIDUAL/INIT",
    FETCH_INDIVIDUAL_SUCCESS: "INDIVIDUAL/FETCH_INDIVIDUAL_SUCCESS"
};

export const initialState = {
    individual: null,
    relationships: null,
    parentsRelationship: null,
    ancestors: null,
    descendants: null,
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.FETCH_INDIVIDUAL_SUCCESS:
            draft.individual = Individual.createFromBackend(action.individual) ?? [];
            draft.relationships = action.relationships?.map(relationship => IndividualRelationship.createFromBackend(relationship)) ?? [];
            draft.parentsRelationship = action.parents ? IndividualParentsRelationship.createFromBackend(action.parents) : null;
            draft.ancestors = IndividualAncestors.createFromBackend(action.ancestors);
            draft.descendants = IndividualDescendants.createFromBackend(action.descendants);
    }
    return draft;
}, initialState);

export const fetchIndividual = (id) => dispatch => {

    const individualClient = new IndividualClient();

    return individualClient.fetchIndividual(id)
        .then(response => response.data)
        .then(data => dispatch({
            type: types.FETCH_INDIVIDUAL_SUCCESS,
            individual: data.data.individual,
            ancestors: data.data.individualAncestors,
            descendants: data.data.individualDescendants,
            parents: data.data.individualParentsRelationship,
            relationships: data.data.individualRelationships,
        }))
        .catch(error => {
            toast.error(`Fehler beim Abruf der Person: ${error}`);
            console.log(error);
        });
};