import React, {useEffect, useState} from "react";
import {FeatureGroup, LayersControl, Map, Marker, Popup, TileLayer} from "react-leaflet";
import LeafletMapCalculationService from "../../services/LeafletMapCalculationService";
import {divIcon} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";

const {BaseLayer, Overlay} = LayersControl;

const basemap = {url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}
const streetmap = {url: 'http://{s}.google.com/vt/lyrs=r,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}
const heighmap = {url: 'http://{s}.google.com/vt/lyrs=p,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}
const topomap = {
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
};
const osmap = {
    url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
};

const renderIcon = () => {
    return divIcon({
        html: renderToStaticMarkup(<i className={"fas fa-map-marker-alt fa-3x marker"}/>)
    })
};


const LeafletMap = ({places}) => {

    const [bounds, setBounds] = useState([{lat: 48.923, lng: 8.651}, {lat: 48.903, lng: 8.684}]);

    useEffect(() => {
        const initBounds = LeafletMapCalculationService.calculateBounds(places);
        setBounds(initBounds)
    }, [places]);

    return (
        <React.Fragment>
            <Map className={'leaflet-map'} bounds={bounds} zoom={5}>

                <LayersControl position="topright" className="hide">
                    <BaseLayer checked name="Base">
                        <TileLayer url={basemap.url} subdomains={basemap.subdomains}/>
                    </BaseLayer>
                    <BaseLayer name="Street">
                        <TileLayer url={streetmap.url} subdomains={streetmap.subdomains}/>
                    </BaseLayer>
                    <BaseLayer name="Height">
                        <TileLayer url={heighmap.url} subdomains={heighmap.subdomains}/>
                    </BaseLayer>
                    <BaseLayer name="Osm">
                        <TileLayer url={osmap.url} attribution={osmap.attribution}/>
                    </BaseLayer>
                    <BaseLayer name="Topo">
                        <TileLayer url={topomap.url} attribution={topomap.attribution}/>
                    </BaseLayer>

                    <Overlay checked={true} name="Gpx Marker">
                        <FeatureGroup color="blue">
                            {places.map((point) => {

                                return (
                                    <Marker key={point.id}
                                            position={[point.lat, point.lon]}
                                            icon={renderIcon()}
                                            onMouseOver={e => {
                                                e.target.openPopup();
                                            }}
                                            onMouseOut={e => {
                                                e.target.closePopup();
                                            }}
                                            onClick={() => console.log('da')}>
                                        <Popup>{point.name}</Popup>
                                    </Marker>
                                )
                            })}
                        </FeatureGroup>
                    </Overlay>
                </LayersControl>
            </Map>
        </React.Fragment>
    )
};

export default LeafletMap;