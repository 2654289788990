import React from "react";
import {Col, Row} from "react-bootstrap";

interface ColHeaderProps {
    text: string,
    className?: string
}

const ColHeader = ({text,className}: ColHeaderProps) => {

    return (
        <Row className={className + " col-header"}>
            <Col>
                <h3>{text}</h3>
            </Col>
        </Row>
    )
};


export default ColHeader;