import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {initialise} from "./reducer";

const VersionInfo = () => {
    const dispatch = useDispatch();

    const {backend, frontend} = useSelector( (state: any) => state.version);


    useEffect(() => {
        dispatch(initialise());
    }, []);


    return (
        <div className="version-info">
            <div><i className="fa fa-cloud" aria-hidden="true"/> {backend} </div>
            <div><i className="fa fa-desktop" aria-hidden="true"/> {frontend}</div>
        </div>
    );
};

export default VersionInfo;