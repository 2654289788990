export default class LeafletMapCalculationService {
    static calculateBounds = (points: any) => {
        if(!points){
            return [{lat: 48.923, lng: 8.651}, {lat: 48.903, lng: 8.684}];
        }

        let latHigh = "48.923";
        let latLow = "48.903";
        let lonHigh = "8.684";
        let lonLow = "8.651";
        points.map( (point: any) => {
            if(parseFloat(point.lat) > parseFloat(latHigh)){
                latHigh = point.lat;
            }
            if(parseFloat(point.lat) < parseFloat(latLow)){
                latLow = point.lat;
            }
            if(parseFloat(point.lon) > parseFloat(lonHigh)){
                lonHigh = point.lon;
            }
            if(parseFloat(point.lon) < parseFloat(lonLow)){
                lonLow = point.lon;
            }
            return point;
        });

        return [{lat: parseFloat(latHigh), lng: parseFloat(lonLow)}, {lat: parseFloat(latLow), lng: parseFloat(lonHigh)}];
    }
}


