import React from "react";
import {Col, Row} from "react-bootstrap";

interface TitleProps {
    title: string,
    subtitle?: string|null
}

const Title = ({title, subtitle}: TitleProps) => {

    return (
        <Row className="title">
            <Col>
                <h2>{title} {subtitle ? <span><br/>{subtitle}</span> : null}</h2>
            </Col>
        </Row>
    )
};

export default Title;