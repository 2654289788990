import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../../../components/title/Title";
import AncestorBranch from "../components/tree/AncestorBranch";
import BranchItem from "../components/tree/BranchItem";
import DescendantBranch from "../components/tree/DescendantBranch";

const TabDescendants = ({individual, descendants}: any) => {

    return (
        <React.Fragment>
            <Container fluid="lg" className="inline">
                <Row>
                    <Col sm={12} md={12} xl={12}>
                        <Title title={"Nachkommen von " + individual.firstName + " " + individual.lastName}/>
                    </Col>
                </Row>
            </Container>
            {descendants.partners && descendants.partners.length > 0
                ?
                <Container fluid className="scrollWindow">
                    <div>
                        <div id="wrapper">
                            <BranchItem individual={individual}/>
                            <DescendantBranch partners={descendants.partners} level={1}/>
                        </div>
                    </div>
                </Container>
                :
                <Container fluid="lg" className="inline">
                    Für diese Person sind noch keine Nachkommen erfasst
                </Container>
            }
        </React.Fragment>
    )
};


export default TabDescendants;
