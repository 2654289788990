import Immerable from "../Immerable";

export default class Place extends Immerable {
    id;
    name;
    lat;
    lon;

    constructor(id, name, lat, lon) {
        super();

        this.id = id;
        this.name = name;
        this.lat = lat;
        this.lon = lon;
    }

    static createFromBackend(json) {
        return new Place(
            json.id,
            json.name,
            json.lat || 48.0,
            json.lon || 8.0
        );
    }
}