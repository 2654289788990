import Immerable from "../Immerable";
import Event from '../Event/Event';
import Individual from "./Individual";

export default class IndividualParentsRelationship extends Immerable {
    id;
    partner1;
    partner2;
    events;
    note;
    siblings;


    constructor(id, partner1, partner2, events, note, siblings) {
        super();

        this.id = id;
        this.partner1 = Individual.createFromBackend(partner1);
        this.partner2 = Individual.createFromBackend(partner2);
        this.events = events?.map((event) => Event.createFromBackend(event));
        this.note = note;
        this.siblings = siblings?.map((sibling) => Individual.createFromBackend(sibling));
    }

    static createFromBackend(json) {
        return new IndividualParentsRelationship(
            json?.id ?? null,
            json.partner1,
            json.partner2,
            json.events,
            json.note,
            json.siblings
        );
    }
}