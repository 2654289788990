import createInstance from "./AxiosFactory";
import Config from "../Config";
import BACKEND_ROUTES from "./BACKEND_ROUTES";

class IndividualClient {
    constructor() {
        this.apiUrl = Config.BACKEND_API;
        this.axios = createInstance();
    }

    fetchListIndividuals() {
        return this.axios.get(this.apiUrl + BACKEND_ROUTES.LIST_INDIVIDUALS);
    }

    fetchAllIndividuals() {
        return this.axios.get(this.apiUrl + BACKEND_ROUTES.ALL_INDIVIDUALS);
    }

    fetchIndividual(id) {
        return this.axios.get(this.apiUrl + BACKEND_ROUTES.INDIVIDUAL + id);
    }
}

export default IndividualClient;