import _ from 'lodash';
import Individual from "../models/Individual/Individual";

export default class IndividualHelper {
    static getFullName = (individual: Individual) => {
        let fullName = individual.prefixFirstName ? individual.prefixFirstName : "";
        fullName = individual.firstName ? fullName + " " +  individual.firstName : fullName;
        fullName = individual.prefixLastName ? fullName + " " +  individual.prefixLastName : fullName;
        fullName = individual.lastName ? fullName + " " +  individual.lastName : fullName;
        fullName = individual.suffixLastName ? fullName + " " +  individual.suffixLastName : fullName;
        return fullName
    };




}

