import IndividualClient from "../../backend/IndividualClient";
import {produce} from "immer";
import {toast} from "react-toastify";
import ListIndividual from "../../models/Individual/ListIndividual";
import Individual from "../../models/Individual/Individual";

export const types = {
    INIT: "INDIVIDUALS/INIT",
    FETCH_LIST_INDIVIDUALS_SUCCESS: "INDIVIDUALS/FETCH_LIST_INDIVIDUALS_SUCCESS",
    FETCH_ALL_INDIVIDUALS_SUCCESS: "INDIVIDUALS/FETCH_ALL_INDIVIDUALS_SUCCESS"
};

export const initialState = {
    list: [],
    all: []
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.FETCH_LIST_INDIVIDUALS_SUCCESS:
            draft.list = action.listIndividuals?.map(individual => ListIndividual.createFromBackend(individual)) ?? [];
        case types.FETCH_ALL_INDIVIDUALS_SUCCESS:
            draft.all = action.individuals?.map(individual => Individual.createFromBackend(individual)) ?? [];
    }
    return draft;
}, initialState);

export const fetchListIndividuals = () => dispatch => {
    const individualClient = new IndividualClient();

    return individualClient.fetchListIndividuals()
        .then(response => response.data)
        .then(data => dispatch({
            type: types.FETCH_LIST_INDIVIDUALS_SUCCESS,
            listIndividuals: data.data
        }))
        .catch(error => {
            toast.error(`Fehler beim Abruf der Namensliste: ${error}`);
            console.log(error);
        });
};

export const fetchAllIndividuals = () => dispatch => {
    const individualClient = new IndividualClient();

    return individualClient.fetchAllIndividuals()
        .then(response => response.data)
        .then(data => dispatch({
            type: types.FETCH_ALL_INDIVIDUALS_SUCCESS,
            individuals: data.data
        }))
        .catch(error => {
            toast.error(`Fehler beim Abruf der Personen: ${error}`);
            console.log(error);
        });
};

export function reloadPage(product) {
    return (dispatch, getState) => {
        const { accountDetails } = getState();

        dispatch({
            type: UPDATE_PRODUCT,
            stateOfResidenceId: accountDetails.stateOfResidenceId,
            product,
        });
    };
}