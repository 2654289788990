import Immerable from "../Immerable";

export default class SearchParams extends Immerable {
    firstName;
    lastName;
    event;
    place;
    date;
    sort;
    sortOrder;

    constructor(firstName, lastName, event, place, date, sort, sortOrder) {
        super();

        this.firstName = firstName;
        this.lastName = lastName;
        this.event = event;
        this.place = place;
        this.date = date;
        this.sort = sort;
        this.sortOrder = sortOrder;
    }

    static empty(json) {
        return new SearchParams("","","","", "", "lastName", "asc");
    }
}