import React from "react";
import {Col} from "react-bootstrap";

const EventNote = ({event}: any) => {

    return (
        <Col xs={2}>
            <i className="fa fa-edit"/>
        </Col>
    )
};


export default EventNote;
