import Immerable from "../Immerable";

export default class ListIndividual extends Immerable {
    id;
    firstName;
    lastName;
    prefixFirstName;
    prefixLastName;
    suffixLastName;
    sex;
    deathplace;
    birthplace;
    age;
    birthdate;
    deathdate;


    constructor(id, firstName, lastName, prefixFirstName, prefixLastName, suffixLastName, sex, deathplace, birthplace, age, birthdate, deathdate) {
        super();

        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.prefixFirstName = prefixFirstName;
        this.prefixLastName = prefixLastName;
        this.suffixLastName = suffixLastName;
        this.sex = sex;
        this.deathplace = deathplace;
        this.birthplace = birthplace;
        this.age = age;
        this.birthdate = birthdate;
        this.deathdate = deathdate;
    }

    static createFromBackend(json) {
        return new ListIndividual(
            json.id,
            json.firstName,
            json.lastName,
            json.prefixFirstName,
            json.prefixLastName,
            json.suffixLastName,
            json.sex,
            json.deathplace,
            json.birthplace,
            json.age,
            json.birthdate,
            json.deathdate
        );
    }
}