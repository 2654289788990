import React from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";

const FormControlButtons = ({onSave, onDelete, onCancel, onDeleteConfirm, deleteConfirmShow, saveDisabled}: any) => {

    return (
        <React.Fragment>
            <hr/>
            <Row>
                {onDelete &&
                <Col>
                    <Button variant="secondary" onClick={() => onDelete()}>
                        <i className="far fa-trash-alt"/>
                    </Button>
                </Col>
                }
                <Col>
                    <Button variant="warning" onClick={() => onCancel()}>
                        <i className="fa fa-times"/>
                    </Button>
                </Col>
                <Col>
                    <Button variant="success" onClick={() => onSave()} disabled={saveDisabled}>
                        <i className="fa fa-check"/>
                    </Button>
                </Col>
            </Row>

            {deleteConfirmShow &&
            <Alert show={true} variant="danger">
                <Row>
                    <Col>
                        Wirlich löschen?
                    </Col>
                    <Col xs={4}>
                        <Button variant="danger" onClick={() => onDeleteConfirm()}>
                            <i className="far fa-trash-alt"/>
                        </Button>
                    </Col>
                </Row>
            </Alert>}
        </React.Fragment>
    );
};


export default FormControlButtons;
