import axios from "axios";
import {toast} from "react-toastify";
import {logoutUser} from "../views/security/reducer";
import StoreSingleton from "../StoreSingleton";

const handleError = error => {
    if (error?.response?.config?.url?.endsWith("/api/login")) {
        throw error;
    }

    if (error?.response?.status === 401 || error?.response?.status === 403) {
        const store = StoreSingleton.getOrCreateReduxStore();
        store.dispatch(logoutUser());
        console.log(error)
        console.log(error.response)
        toast.error("Ihr Anmeldetoken ist abgelaufen, bitte melden Sie sich erneut an.");
    }
    throw error;
};

const createInstance = () => {
    let authToken = "";

    if (localStorage.getItem("authToken")) {
        authToken = localStorage.getItem("authToken");
    } else if (sessionStorage.getItem("authToken")) {
        authToken = sessionStorage.getItem("authToken");
    }


    const config = {
        timeout: 15000,
        headers: {"Authorization": "Bearer " + authToken}
    };

    if (process.env.REACT_APP_BACKEND_URL) {
        config.baseURL = process.env.REACT_APP_BACKEND_URL;
    }

    const axiosInstance = axios.create(config);

    axiosInstance.interceptors.response.use(
        response => response,
        error => handleError(error)
    );

    return axiosInstance;
};

export default createInstance;