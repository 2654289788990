import Immerable from "../Immerable";
import Event from '../Event/Event';

export default class Individual extends Immerable {
    id;
    firstName;
    lastName;
    prefixFirstName;
    prefixLastName;
    suffixLastName;
    sex;
    note;
    events;
    facts;
    gedcomId;
    privacy;

    constructor(id, firstName, lastName, prefixFirstName, prefixLastName, suffixLastName, sex, note, events, facts, gedcomId, privacy) {
        super();
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.prefixFirstName = prefixFirstName;
        this.prefixLastName = prefixLastName;
        this.suffixLastName = suffixLastName;
        this.sex = sex;
        this.note = note;
        this.events = events?.map((event) => Event.createFromBackend(event));
        this.facts = facts;
        this.gedcomId = gedcomId;
        this.privacy = privacy;

    }

    static createFromBackend(json) {
        return new Individual(
            json.id,
            json.firstName,
            json.lastName,
            json.prefixFirstName,
            json.prefixLastName,
            json.suffixLastName,
            json.sex,
            json.note,
            json.events,
            json.facts,
            json.gedcomId,
            json.privacy
        );
    }
}