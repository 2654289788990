import React from "react";
import {Col, Row} from "react-bootstrap";
import IndividualCard from "../components/individualcard/IndividualCard";
import Title from "../../../components/title/Title";
import IndividualRelationship from "../../../models/Individual/IndividualRelationship";
import Individual from "../../../models/Individual/Individual";
import Secured from "../../security/Secured";
import FormModal from "../../form/component/FormModal";
import ColHeader from "../components/header/ColHeader";
import IndividualHelmet from "../components/helmet/IndividualHelmet";
import RelationshipFormDto from "../../../models/Form/RelationshipFormDto";
import RelationshipChildFormDto from "../../../models/Form/RelationshipChildFormDto";
import ChildUnlinkButton from "../../form/component/ChildUnlinkButton";
import RelationshipUnlinkButton from "../../form/component/RelationshipUnlinkButton";
import RelationshipEventsCard from "../components/relationshipeventcard/RelationshipEventsCard";

const TabRelationships = ({individual, relationships}: any) => {

    const onSave = (something: any) => {
        console.log(something)
    }

    return (
        <React.Fragment>
            <Row>
                <Col sm={12} md={6} xl={6}>
                    <Title title={"Familie mit"} subtitle={individual.firstName + " " + individual.lastName}/>
                </Col>
                <Col sm={12} md={6} xl={6}>
                    <IndividualCard individual={individual}/>
                </Col>
                <Col sm={12} md={6} xl={6}>
                    &nbsp;
                </Col>
            </Row>

            <hr/>

            {relationships.map((relationship: IndividualRelationship) => {
                return (
                    <React.Fragment key={relationship.id}>
                        <IndividualHelmet prefix={"Familie mit"} individual={individual}/>

                        <Row>
                            <Col sm={12} md={6} xl={6}>
                                <ColHeader text="Partner"/>
                                {relationship && relationship.partner &&
                                <div className="unlink-box">
                                    <IndividualCard individual={relationship.partner}/>
                                    <RelationshipEventsCard relationship={relationship}/>
                                    <Secured>
                                        <RelationshipUnlinkButton relationshipId={relationship.id}/>
                                    </Secured>
                                </div>}

                            </Col>
                            <Col sm={12} md={6} xl={6}>
                                <ColHeader text={"Kinder mit " + relationship.partner.firstName} className="children"/>
                                {relationship.children.map((child: Individual) => {
                                    return (
                                        <div className="unlink-box" key={child.id}>
                                            <IndividualCard individual={child} key={child.id}/>
                                            <Secured>
                                                <ChildUnlinkButton childId={child.id}/>
                                            </Secured>
                                        </div>

                                    );
                                })}
                                <Secured>
                                    <FormModal
                                        title={'Kind von ' + individual.firstName + " und " + relationship.partner.firstName + " hinzufügen"}
                                        createFormFrom={RelationshipChildFormDto.createNewForRelationship(relationship)}>
                                        <button className="add-button">Kind dieser Beziehung hinzufügen</button>
                                    </FormModal>
                                </Secured>
                            </Col>
                            <Col sm={12} md={6} xl={6}>
                                &nbsp;
                            </Col>
                        </Row>

                        <hr/>
                    </React.Fragment>
                );

            })}

            <Row>
                <Col sm={12} md={6} xl={6}>
                    <Secured>
                        <FormModal
                            title={'Partner von ' + individual.firstName + " " + individual.lastName + " hinzufügen"}
                            createFormFrom={RelationshipFormDto.createNewForIndividual(individual)}>
                            <button className="add-button">Partnerschaft hinzufügen</button>
                        </FormModal>
                    </Secured>
                </Col>
            </Row>

        </React.Fragment>
    )
};


export default TabRelationships;
