import React from "react";
import ConfirmModal from "./ConfirmModal";
import {removeRelationship} from "../reducer";
import {eventRelationshipRemoved} from "../../../components/eventBus/reducer";
import {useDispatch} from "react-redux";

const RelationshipUnlinkButton = ({relationshipId}: any) => {

    const dispatch = useDispatch();

    const onSave = () => {
        removeRelationship(relationshipId).then(() => {
            dispatch(eventRelationshipRemoved());
        });
    };

    return (
        <ConfirmModal onSave={onSave} title="Beziehung entfernen?">
            <div className="unlink-button">
                <i className="fa fa-unlink"/>
            </div>
        </ConfirmModal>
    );
};


export default RelationshipUnlinkButton;
