import Immerable from "../Immerable";
import Individual from "../Individual/Individual";

export default class IndividualFormDto extends Immerable {
    id;
    firstName;
    lastName;
    sex;
    prefixFirstName;
    prefixLastName;
    suffixLastName;
    note;

    constructor(id: number | null, firstName: string, lastName: string, sex: string, prefixFirstName: string, prefixLastName: string, suffixLastName: string, note: string | null) {
        super();

        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.sex = sex;
        this.prefixFirstName = prefixFirstName;
        this.prefixLastName = prefixLastName;
        this.suffixLastName = suffixLastName;
        this.note = note || "";

    }

    static createFromIndividual(individual: Individual) {
        return new IndividualFormDto(
            individual.id,
            individual.firstName,
            individual.lastName,
            individual.sex,
            individual.prefixFirstName,
            individual.prefixLastName,
            individual.suffixLastName,
            individual.note
        );
    }

    static new() {
        return new IndividualFormDto(null, "", "", "M", "", "", "", null);
    }
}








