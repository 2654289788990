import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {pagination, tableConfig} from "./TableConfig";

import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {Col, Row} from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import Secured from "../../security/Secured";
import FormModal from "../../form/component/FormModal";
import IndividualFormDto from "../../../models/Form/IndividualFormDto";


interface ListIndividuals {
    individuals: []
}

const Table = ({individuals}: ListIndividuals) => {

    return (
        <ToolkitProvider bootstrap4 keyField='id' data={individuals} columns={tableConfig} search>
            {
                props => (
                    <React.Fragment>
                        <Row className="align-items-end">
                            <Col>
                                <Search.SearchBar {...props.searchProps} placeholder="Suche nach: Name, Datum, Ort..."/>
                            </Col>
                            <Secured>
                                <Col xs={3}>
                                    <FormModal title="Neue Person" createFormFrom={IndividualFormDto.new()}>
                                        <button className="add-button button-small mb-2"><i className="fas fa-plus"/> Person hinzufügen</button>
                                    </FormModal>
                                </Col>
                            </Secured>
                        </Row>

                        {individuals.length == 0
                            ? <Loading/>
                            : <BootstrapTable
                                pagination={pagination}
                                {...props.baseProps}
                                wrapperClasses="table-responsive table-container"
                                striped
                                hover/>
                        }
                    </React.Fragment>
                )
            }
        </ToolkitProvider>

    )
};

export default Table;