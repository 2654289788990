import React from "react";
import {Col, Row} from "react-bootstrap";
import EventHelper from "../../../../services/EventHelper";

const EventName = ({event}: any) => {

    return (
            <Col xs={8} md={12}>
                {EventHelper.getReadableEventName(event.name)}
            </Col>
    )
};

export default EventName;
