import produce from "immer";
import Config from "../../Config";
import createInstance from "../../backend/AxiosFactory";
import * as toast from "react-toastify";

export const types = {
    VERSIONINFO_BACKEND: "VERSIONINFO/BACKEND",
    VERSIONINFO_FRONTEND: "VERSIONINFO/FRONTEND"
};

export const initialState = {
        backend: '0.0.0',
        frontend: '0.0.0'
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.VERSIONINFO_BACKEND:
            draft.backend = action.backendVersion;
            return draft;
        case types.VERSIONINFO_FRONTEND:
            draft.frontend = action.frontendVersion;
            return draft;
    }
    return draft;
}, initialState);


// const sessionTimout = () => {
//     localStorage.removeItem("authToken");
//
//     return {
//         type: types.SESSION_TIMEOUT,
//         user: {
//             isAuthenticated: false,
//             authToken: null
//         }
//     };
// };
//
// export const logoutUser = () => dispatch => {
//     dispatch({type: "INITIALISE_APP"});
//     dispatch(sessionTimout());
// };
//
// const receiveLogin = authToken => {
//     localStorage.setItem("authToken", authToken);
//
//     return {
//         type: types.LOGIN_SUCCESS,
//         user: {
//             isAuthenticated: true,
//             authToken
//         }
//     };
// };

export const getBackendVersion = () => {
    const path = Config.INFO_API + "/info";
    const axios = createInstance();

    return dispatch => axios.get(path)
        .then(response => {
            dispatch({
                type: types.VERSIONINFO_BACKEND,
                backendVersion: response.data,
            });
        })
        .catch(err => {
            console.log(err)
        });
};

export const getFrontendVersion = () => {
    const path = "/application.info";
    const axios = createInstance();

    return dispatch => axios.get(path)
        .then(response => {
            dispatch({
                type: types.VERSIONINFO_FRONTEND,
                frontendVersion: response.data,
            });
        })
        .catch(err => {
            console.log(err)
        });
};

export const initialise = () => dispatch => {
    dispatch(getBackendVersion());
    dispatch(getFrontendVersion());
};