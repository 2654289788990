import createInstance from "./AxiosFactory";
import Config from "../Config";
import BACKEND_ROUTES from "./BACKEND_ROUTES";

class PlaceClient {
    constructor() {
        this.apiUrl = Config.BACKEND_API + BACKEND_ROUTES.PLACES;
        this.axios = createInstance();
    }

    fetchPlaces() {
        return this.axios.get(this.apiUrl);
    }
}

export default PlaceClient;