import React, {useState} from "react";
import IndividualEventFormDto from "../../models/Form/IndividualEventFormDto";
import RelationshipEventForm from "../../models/Form/RelationshipEventFormDto";
import EventHelper from "../../services/EventHelper";
import {Form} from "react-bootstrap";
import FormControlButtons from "./component/FormControlButtons";
import EventForm from "./forms/EventForm";
import {deleteEvent, saveRelationshipEvent} from "./reducer";
import {eventIndividualEventSaved} from "../../components/eventBus/reducer";
import {useDispatch} from "react-redux";


interface FormProps {
    event: IndividualEventFormDto | RelationshipEventForm,
    setShow?: any
}

const RelationshipEventFormContainer = ({event, setShow}: FormProps) => {
    const [form, setForm] = useState<any>(event);
    const [deleteConfirmShow, setDeleteConfirmShow] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        let {name, value} = e.target;

        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSave = () => {
        saveRelationshipEvent(form).then(() => {
            console.log(form)
            dispatch(eventIndividualEventSaved());
            setShow(false)
        });
    };
    const onDelete = () => {
        setDeleteConfirmShow(true)
    };

    const onDeleteConfirm = () => {
        deleteEvent(event.id).then(() => {
            dispatch(eventIndividualEventSaved());
            setShow(false)
        });
    };

    const onCancel = () => {
        setForm(event);
        setShow(false);
    };

    const renderOptions = (form: any) => {
        return (
            <Form.Control as="select" onChange={(e: any) => handleChange(e)}
                          name="name"
                          value={form.name ? form.name : ''}>
                <option value="" disabled>Wählen</option>
                <option value="" disabled>───────────────────</option>
                {EventHelper.getRelationshipEvents().map((name: string) => {
                    return (
                        <option value={name}>{EventHelper.getReadableEventName(name)}</option>
                    );
                })}
            </Form.Control>
        );
    };

    return (
        <React.Fragment>
            <EventForm form={form} handleChange={handleChange} renderOptions={renderOptions}/>


            <FormControlButtons onSave={onSave} onDelete={form.id && onDelete} onCancel={onCancel}
                                onDeleteConfirm={onDeleteConfirm} deleteConfirmShow={deleteConfirmShow}/>


        </React.Fragment>
    )
};


export default RelationshipEventFormContainer;
