import React from "react";
import BranchItem from "./BranchItem";

const AncestorBranch = ({entries, level}: any) => {

    return (
        <div className={"branch lv" + level}>
            {entries.map((entry: any) => {
                return (
                    <div className="entry" key={entry.id}>
                        <BranchItem individual={entry}/>
                        {entry.parents &&
                        <AncestorBranch entries={[entry.parents.partner1, entry.parents.partner2]} level={level + 1}/>}
                    </div>
                );
            })}
        </div>

    )
};


export default AncestorBranch;
