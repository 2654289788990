import {toast} from "react-toastify";
import produce from "immer";
import ClientJS from "clientjs";
import createInstance from "../../backend/AxiosFactory";
import Config from "../../Config";
import {initialise} from "../versioninfo/reducer"

export const types = {
    INIT: "SECURITY/INIT",
    LOGIN_SUCCESS: "SECURITY/LOGIN_SUCCESS",
    SESSION_TIMEOUT: "SECURITY/SESSION_TIMEOUT"
};

export const initialState = {
    user: {
        isAuthenticated: !!localStorage.getItem("authToken"),
        authToken: localStorage.getItem("authToken"),
        id: localStorage.getItem("id")
    }
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
        case types.SESSION_TIMEOUT:
            draft.user = action.user;
            return draft;
    }
}, initialState);

const sessionTimout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("id");
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("id");


    return {
        type: types.SESSION_TIMEOUT,
        user: {
            isAuthenticated: false,
            authToken: null,
            id: null
        }
    };
};

const hashCode = (s) => {
    for (var i = 0, h = 0; i < s.length; i++)
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    return h;
};

export const logoutUser = () => dispatch => {
    dispatch({type: "INITIALISE_APP"});
    dispatch(sessionTimout());
    dispatch(initialise());
};

const receiveLogin = (authToken, fingerprint, stayLoggedIn) => {
    if(stayLoggedIn){
        localStorage.setItem("authToken", authToken);
        localStorage.setItem("id", fingerprint);
    } else {
        sessionStorage.setItem("authToken", authToken);
        sessionStorage.setItem("id", fingerprint);
    }


    return {
        type: types.LOGIN_SUCCESS,
        user: {
            isAuthenticated: true,
            authToken,
            id: fingerprint
        }
    };
};

export const loginUser = (username, password, stayLoggedIn) => {
    const client = new ClientJS();
    const result = client.getResult();
    const fingerprint = hashCode(JSON.stringify(result));

    const params = {
        username,
        password,
        fingerprint,
        stayLoggedIn
    };

    const path = Config.AUTH_API + "/login";
    const axios = createInstance();

    return dispatch => axios.post(path, params)
        .then(response => {
            console.log(response)
            const authToken = response.data.token;
            const stayLoggedIn = response.data.stayLoggedIn;
            if (!authToken) {
                return;
            }
            dispatch(receiveLogin(authToken, fingerprint, stayLoggedIn));
        })
        .catch(err => {
            toast.error(`Beim Anmelden ist ein Fehler aufgetreten: ${err}`);
        });
};