import React from "react";
import Title from "../../../components/title/Title";

const IndividualStatistics = () => {

    return (
        <React.Fragment>
            <Title title="Statistik :: Personen" />
        </React.Fragment>
    )
};

export default IndividualStatistics;