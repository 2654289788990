import React, {useState} from "react";
import NavigationLink from "./NavigationLink";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useSelector} from "react-redux";
import useWindowDimensions from "../../services/WindowDimensions";
import img from "../../assets/header_bg.png";
import NavigationDropdownLink from "./NavigationDropdownLink";

const Navigation = () => {

    const [expanded, setExpanded] = useState(false);
    const {user} = useSelector((state: any) => state.security);
    const {height, width} = useWindowDimensions();
    const mobileViewport = 768;

    const onClick = () => {
        setExpanded(!expanded)
    };

    return (

        <Navbar variant="dark"
                expand="md"
                expanded={width < mobileViewport ? expanded : true}
                sticky="top"
                bg="dark">
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
            <div className="title-navigation"><h1>Genealogie<span>Familien Wurster / Rist</span></h1></div>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <NavigationLink to={"/"} icon={"fa fa-home"} name={"Home"}/>

                    {/*todo: remove "isAuthenticated"*/}

                    {/*   --->   */}
                    {user.isAuthenticated &&
                            <div style={{'display': 'flex'}}>
                        <NavDropdown title={<span><i className="fa fa-list-ol"/> Übersicht</span>} id="basic-nav-dropdown">
                        <NavigationDropdownLink to={"/individuals"} icon={"fas fa-user"} name={"Personen"}
                        onClick={onClick}/>
                        <NavigationDropdownLink to={"/families"} icon={"fas fa-users"} name={"Familien"}
                        onClick={onClick}/>
                        <NavigationDropdownLink to={"/places"} icon={"fa fa-globe"} name={"Orte"} onClick={onClick}/>
                        </NavDropdown>

                        <NavDropdown title={<span><i className="fas fa-chart-bar"/> Statistiken</span>}
                        id="basic-nav-dropdown">
                        <NavigationDropdownLink to={"/statistics"} icon={"fas fa-chart-bar"} name={"Allgemein"}
                        onClick={onClick}/>
                        <NavigationDropdownLink to={"/statistics/individuals"} icon={"fas fa-user"} name={"Personen"}
                        onClick={onClick}/>
                        <NavigationDropdownLink to={"/statistics/families"} icon={"fa fa-users"} name={"Familien"}
                        onClick={onClick}/>
                        </NavDropdown>

                        <NavigationLink to={"/search"} icon={"fas fa-search"} name={"Suche"} onClick={onClick}/>
                        </div>
                    }

                    {/*   <---   */}




                </Nav>

                <Nav className="ml-auto">
                    <NavigationLink to={"/legal"} icon={"fa fa-file-contract"} name={"Impressum"} onClick={onClick}/>
                    {!user.isAuthenticated
                        ? <NavigationLink to={"/login"} icon={"fa fa-users"} name={"Login"} onClick={onClick}/>
                        : <NavigationLink to={"/logout"} icon={"fa fa-users"} name={"Logout"} onClick={onClick}/>}
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
};

export default Navigation;
