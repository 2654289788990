import IndividualClient from "../../backend/IndividualClient";
import {produce} from "immer";
import {toast} from "react-toastify";
import Place from "../../models/Place/Place";
import PlaceClient from "../../backend/PlaceClient";

export const types = {
    INIT: "PLACES/INIT",
    FETCH_PLACES_SUCCESS: "PLACES/FETCH_PLACES_SUCCESS"
};

export const initialState = {
    list: []
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.FETCH_PLACES_SUCCESS:
            draft.list = action.places?.map(place => Place.createFromBackend(place)) ?? [];
    }
    return draft;
}, initialState);

export const fetchPlaces = () => dispatch => {
    const placeClient = new PlaceClient();

    return placeClient.fetchPlaces()
        .then(response => response.data)
        .then(data => dispatch({
            type: types.FETCH_PLACES_SUCCESS,
            places: data.data
        }))
        .catch(error => {
            toast.error(`Fehler beim Abruf der Orte: ${error}`);
            console.log(error);
        });
};