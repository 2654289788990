import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import SearchForm from "./forms/SearchForm";
import SearchParams from "../../models/Search/SearchParams";
import {fetchSearchResults, resetSearchResults} from "../search/reducer";

const SearchFormContainer = () => {
    const [queryParams, setQueryParams] = useState<any>(SearchParams.empty());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetSearchResults());
    }, []);

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setQueryParams((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            onSearch(event);
        }
    };

    const onSearch = (event:any) => {
        event.target.blur();
        dispatch(fetchSearchResults(queryParams))
    };

    const onReset = () => {
        dispatch(resetSearchResults());
    };

    return (
        <React.Fragment>
            <SearchForm queryParams={queryParams} handleChange={handleChange} handleKeyPress={handleKeyPress}/>
        </React.Fragment>
    )
};


export default SearchFormContainer;
