import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import IndividualEventFormDto from "../../../models/Form/IndividualEventFormDto";
import IndividualEventFormContainer from "../IndividualEventFormContainer";
import IndividualFormContainer from "../IndividualFormContainer";
import IndividualFormDto from "../../../models/Form/IndividualFormDto";
import RelationshipFormDto from "../../../models/Form/RelationshipFormDto";
import RelationshipFormContainer from "../RelationshipFormContainer";
import RelationshipChildFormDto from "../../../models/Form/RelationshipChildFormDto";
import RelationshipChildFormContainer from "../RelationshipChildFormContainer";
import ParentsFormContainer from "../ParentsFormContainer";
import ParentsFormDto from "../../../models/Form/ParentsFormDto";
import RelationshipEventFormDto from "../../../models/Form/RelationshipEventFormDto";
import RelationshipEventFormContainer from "../RelationshipEventFormContainer";

export interface LayoutProps {
    children: React.ReactNode,
    title: string,
    createFormFrom: any
}


const FormModal = ({children, title, createFormFrom}: LayoutProps) => {

    const [show, setShow] = useState(false);

    return (
        <React.Fragment>

            <div onClick={() => {
                setShow(!show);
            }}>
                {children}
            </div>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title ? title : 'TITEL VERGESSEN'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {createFormFrom instanceof IndividualEventFormDto &&
                    <IndividualEventFormContainer event={createFormFrom} setShow={setShow}/>}
                    {createFormFrom instanceof RelationshipEventFormDto &&
                    <RelationshipEventFormContainer event={createFormFrom} setShow={setShow}/>}
                    {createFormFrom instanceof IndividualFormDto &&
                    <IndividualFormContainer individual={createFormFrom} setShow={setShow}/>}
                    {createFormFrom instanceof RelationshipFormDto &&
                    <RelationshipFormContainer relationship={createFormFrom} setShow={setShow}/>}
                    {createFormFrom instanceof RelationshipChildFormDto &&
                    <RelationshipChildFormContainer relationshipChild={createFormFrom} setShow={setShow}/>}
                    {createFormFrom instanceof ParentsFormDto &&
                    <ParentsFormContainer parents={createFormFrom} setShow={setShow}/>}
                </Modal.Body>
            </Modal>


        </React.Fragment>
    )
};


export default FormModal;
