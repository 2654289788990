import React from "react";
import {NavLink} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";

interface Props {
    to: string;
    icon: string;
    name: string;
    onClick: any;
}

const NavigationDropdownLink = ({to, icon, name, onClick}: Props) => {


    return (
        <NavDropdown.Item as={NavLink} to={to} onClick={onClick} className="nav-link">
            <i className={icon}></i> {name}
        </NavDropdown.Item>
    )
};

export default NavigationDropdownLink;