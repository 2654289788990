import React from "react";
import Title from "../../components/title/Title";
import {Card, Col, Container, Row} from "react-bootstrap";
import SearchFormContainer from "../form/SearchFormContainer";
import {useSelector} from "react-redux";
import IndividualCard from "../individual/components/individualcard/IndividualCard";
import ColHeader from "../individual/components/header/ColHeader";
import Loading from "../../components/loading/Loading";

const Search = () => {

    const {results} = useSelector((state: any) => state.search);
    const {count} = useSelector((state: any) => state.search);
    const {isSearching} = useSelector((state: any) => state.search);


    return (
        <Container fluid="lg" className="inline">
            <Title title="Suche"/>

            <Row>

                <Col sm={12} md={6} xl={6}>
                    {/*<Card bg="dark" border="light">*/}
                    {/*    <Card.Body>*/}
                            <SearchFormContainer/>
                        {/*</Card.Body>*/}
                    {/*</Card>*/}
                </Col>


                <Col sm={12} md={6} xl={6} className="col-left-border">
                    {isSearching ? <Loading /> : <ColHeader text={count + " Treffer"}/>}


                    {results.map((individual: any) => {
                        return (
                            <IndividualCard individual={individual} key={individual.id}/>
                        );
                    })}
                </Col>


            </Row>

        </Container>
    )
};

export default Search;