import createInstance from "./AxiosFactory";
import Config from "../Config";
import BACKEND_ROUTES from "./BACKEND_ROUTES";

class SearchClient {
    constructor() {
        this.apiUrl = Config.BACKEND_API + BACKEND_ROUTES.SEARCH;
        this.axios = createInstance();
    }

    search(params) {
        return this.axios.get(this.apiUrl, {params});
    }
}

export default SearchClient;