import React from "react";
import Title from "../../components/title/Title";
import {Helmet} from "react-helmet";

const Legal = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Impressum</title>
                <meta name="description" content="Impressum der Staummbaum-Seite" />
                <meta name="theme-color" content="#008f68" />
            </Helmet>

            <Title title="Impressum"/>

            <div className="row">
                <div className="col-sm-12">
                    <h3>Angaben gemäß § 5 TMG</h3>
                    <p>
                        Michael Wurster<br/>
                        Am Rothenrain 15<br/>
                        75228 Ispringen
                    </p>
                    <h3>Kontakt</h3>
                    <p>
                        E-Mail: sb@softcreations.de<br/>
                    </p>
                    <hr/>
                    <h3>Hinweis Vorab</h3>
                    <p>
                        Diese Seite ist eine rein private Informationsseite ohne geschäftsmäßige oder
                        journalistisch-redaktionelle Inhalte.
                    </p>
                    <h3>Allgemeine Datenschutzerklärung</h3>
                    <p>
                        Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung
                        von Daten gemäß der nachfolgenden Beschreibung einverstanden:<br/>
                        Die Website kann grundsätzlich ohne Registrierung besucht werden. Dabei werden Daten wie
                        beispielsweise aufgerufene Seiten bzw.
                        Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server
                        gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene
                        Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden auf dieser Seite generell nicht
                        erhoben. <br/>
                        Wenn Sie allerdings über die oben genannte E-Mail-Adresse mit mir in Verbindung treten,
                        sollte Ihnen bewusst sein, dass dann natürlich Ihre E-Mail-Adresse an mich übermittelt wird. <br/>
                        Diese Daten werden ausschließlich für Kommunikation bezüglich Ihrer Kontakaufnahme verwendet.
                        Eine Weitergabe der Daten an Dritte erfolgt nicht.
                    </p>

                    <h3>Haftungsausschluss</h3>
                    <p>
                        Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                        Vollständigkeit und Aktualität der Inhalte kann ich jedoch keine Gewähr übernehmen. Als
                        Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als Diensteanbieter jedoch
                        nicht
                        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                        Umständen
                        zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                        Eine
                        diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde ich
                        diese
                        Inhalte umgehend entfernen
                    </p>
                </div>
            </div>
        </React.Fragment>


    )
};

export default Legal;