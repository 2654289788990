enum BAKEND_ROUTES {
    LIST_INDIVIDUALS = "/gedcom/individuals/list",
    ALL_INDIVIDUALS = "/gedcom/individuals",
    INDIVIDUAL = "/gedcom/individual/",
    FAMILIES = "/gedcom/families",
    PLACES = "/gedcom/places",
    SEARCH = "/gedcom/search",
    FORM_INDIVIDUAL_SAVE = "/gedcom/individual/save",
    FORM_INDIVIDUAL_EVENT_SAVE = "/gedcom/individual/event/save",
    FORM_INDIVIDUAL_EVENT_DELETE = "/gedcom/individual/event/delete",
    FORM_INDIVIDUAL_PARENTS_SAVE = "/gedcom/individual/parents/save",
    FORM_RELATIONSHIP_SAVE = "/gedcom/relationship/save",
    FORM_RELATIONSHIP_EVENT_SAVE = "/gedcom/relationship/event/save",
    FORM_RELATIONSHIP_REMOVE = "/gedcom/relationship/remove",
    FORM_RELATIONSHIP_CHILD_SAVE = "/gedcom/relationship/child/save",
    FORM_RELATIONSHIP_CHILD_REMOVE = "/gedcom/relationship/child/remove"
}

export default BAKEND_ROUTES;