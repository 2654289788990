import React from "react";
import * as _ from "lodash";
import EventHelper from "../../../../services/EventHelper";
import DateFormatter from "../../../../services/DateFormatter";

const BranchItemEvents = ({individualEvents}:any) => {

    const getFormatedEventDate = (events:any, eventName:string) => {
        const e = EventHelper.findEventIfExistByName(events, eventName);
        let date = "";
        if(e && e.date){
            date = DateFormatter.readableDateFormatter(e.date)
        }
        return date;
    };


    return (
        <span className="events">
            <span className="larger">*</span> {getFormatedEventDate(individualEvents, 'BIRT')}
            &nbsp;-&nbsp;
            <span className="larger">†</span> {getFormatedEventDate(individualEvents, 'DEAT')}
        </span>
    )
}

export default BranchItemEvents;
