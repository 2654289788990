import _ from 'lodash';

export default class EventHelper {
    static findEventIfExistByName = (events: any, eventName: string) => {
        return _.find(events, function (obj) {
            return obj.name === eventName;
        });
    };

    static getReadableEventName = (name: string) => {
        switch (name) {
            case "BIRT":
                return "Geboren";
            case "DEAT":
                return "Gestorben";
            case "ADOP":
                return "Adoption";
            case "MARR":
                return "Hochzeit";
            case "DIV":
                return "Scheidung";
            case "ENGA":
                return "Verlobung";
            case "OCCU":
                return "Beruf";
            case "BAPM":
                return "Taufe";
            case "BURI":
                return "Beerdigung";
            default:
                return "unbekanntes Event"
        }
    };



    static getRelationshipEvents = () => {
        return ["ENGA","MARR","DIV"];
    }

    static getIndividualEvents = () => {
        return ["BIRT","DEAT","ADOP","OCCU","BAPM","BURI"];
    }
    static getEvents = () => {
        return EventHelper.getIndividualEvents().concat(EventHelper.getRelationshipEvents())
    }


}



const eventNames = {
    BIRT: 'Geboren',
    DEAT: 'Gestorben',
    OCCU: 'Beruf',
    BAPM: 'Taufe',
    ADOP: 'Adoption',
    BURI: 'Beerdigung',
    MARR: 'Hochzeit',
    DIV: 'Scheidung',
    ENGA: 'Verlobung',
    x: 'unknown'
};
