import React from "react";
import HeaderTop from "./header/HeaderTop";
import Navigation from "./header/Navigation";
import Footer from "./footer/Footer";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./home/Home";
import Login from "./security/Login";
import Logout from "./security/Logout";
import Legal from "./legal/Legal";
import Families from "./families/Families";
import Individuals from "./individuals/Individuals";
import Places from "./places/Places";
import GeneralStatistics from "./statistics/general/GeneralStatistics";
import IndividualStatistics from "./statistics/individuals/IndividualStatistics";
import FamilySatistics from "./statistics/families/FamilySatistics";
import Search from "./search/Search";
import ROUTES from "../backend/ROUTES";
import Individual from "./individual/Individual";
import {Helmet} from "react-helmet";


const App = () => {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Helmet>
                    <title>Genealogie der Familien Wurster und Rist</title>
                    <meta name="description"
                          content="Die Genealogieseite der Familien Wurster und Rist mit den Familien Bohnenber und Kalmbach aus dem Kreis Calw in Baden-Württemberg, Deutschland"/>
                </Helmet>
                <HeaderTop/>
                <Navigation/>
                <main>
                    <div className="content-container container-fluid bg-dark text-light">
                        <Switch>
                            <Route path={ROUTES.INDEX} exact component={Home}/>
                            <Route path={ROUTES.LOGIN} exact component={Login}/>
                            <Route path={ROUTES.LOGOUT} exact component={Logout}/>
                            <Route path={ROUTES.INDIVIDUAL} exact component={Individual}/>
                            <Route path={ROUTES.INDIVIDUALS} exact component={Individuals}/>
                            <Route path={ROUTES.FAMILIES} exact component={Families}/>
                            <Route path={ROUTES.PLACES} exact component={Places}/>
                            <Route path={ROUTES.STATISTICS} exact component={GeneralStatistics}/>
                            <Route path={ROUTES.STATISTICS_INDIVIDUALS} exact component={IndividualStatistics}/>
                            <Route path={ROUTES.STATISTICS_FAMILIES} exact component={FamilySatistics}/>
                            <Route path={ROUTES.SEARCH} exact component={Search}/>
                            <Route path={ROUTES.LEGAL} exact component={Legal}/>
                        </Switch>
                    </div>
                </main>
                <Footer/>
            </BrowserRouter>
        </React.Fragment>
    )

};

export default App;
