import React, {useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {useParams} from "react-router";
import Loading from "../../components/loading/Loading";
import {fetchIndividual} from "./reducer";
import {useDispatch, useSelector} from "react-redux";
import TabIndividual from "./tabs/TabIndividual";
import Secured from "../security/Secured";
import TabParents from "./tabs/TabParents";
import TabRelationships from "./tabs/TabRelationships";
import {fetchListIndividuals} from "../individuals/reducer";
import {Container} from "react-bootstrap";
import TabAncestors from "./tabs/TabAncestors";
import TabDescendants from "./tabs/TabDescendants";

const Individual = () => {
    const dispatch = useDispatch();
    const {id} = useParams<{ id: string }>();
    const [tabIndex, setTabIndex] = useState<number>(0);

    const {user} = useSelector((state: any) => state.security);
    const {individualEvent} = useSelector((state: any) => state.events);
    const {individual} = useSelector((state: any) => state.individual);
    const {relationships} = useSelector((state: any) => state.individual);
    const {parentsRelationship} = useSelector((state: any) => state.individual);
    const {ancestors} = useSelector((state: any) => state.individual);
    const {descendants} = useSelector((state: any) => state.individual);


    useEffect(() => {
        dispatch(fetchIndividual(id));
    }, [id, individualEvent]);

    useEffect(() => {
        dispatch(fetchListIndividuals());
    }, [user]);

    return (
        <React.Fragment>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab><i className="fa fa-user"/> <span>Person</span></Tab>
                    <Tab><i className="fa fa-users"/> <span>Familie mit Eltern</span></Tab>
                    <Tab><i className="fa fa-user-friends"/> <span>Eigene Familie</span></Tab>
                    <Tab><i className="fa fa-sitemap fa-flip-vertical"/> <span>Ahnentafel</span></Tab>
                    <Tab><i className="fa fa-sitemap"/> <span>Stammbaum</span></Tab>
                    <Secured>
                        <Tab><i className="fa fa-sitemap"/> <span>Admin</span></Tab>
                    </Secured>
                </TabList>

                <TabPanel>
                    <Container fluid="lg" className="inline">
                        {!individual ? <Loading/> : <TabIndividual individual={individual}/>}
                    </Container>
                </TabPanel>
                <TabPanel>
                    <Container fluid="lg" className="inline">
                        {!individual && !parentsRelationship ? <Loading/> :
                            <TabParents individual={individual} parents={parentsRelationship}/>}
                    </Container>
                </TabPanel>
                <TabPanel>
                    <Container fluid="lg" className="inline">
                        {!individual && !relationships ? <Loading/> :
                            <TabRelationships individual={individual} relationships={relationships}/>}
                    </Container>
                </TabPanel>
                <TabPanel>
                    {!individual && !ancestors ? <Loading/> :
                        <TabAncestors individual={individual} ancestors={ancestors}/>}
                </TabPanel>
                <TabPanel>
                    {!individual && !descendants ? <Loading/> :
                        <TabDescendants individual={individual} descendants={descendants}/>}
                </TabPanel>
                <Secured>
                    <TabPanel>
                        <Loading/>
                        <h3>Admin</h3>
                    </TabPanel>
                </Secured>

            </Tabs>
        </React.Fragment>
    )
};

export default Individual;