import Immerable from "../Immerable";
import Event from '../Event/Event';

export default class RelationshipEventFormDto extends Immerable {
    id;
    relationshipId;
    dateMin;
    dateMax;
    dateAccuracy;
    eventOccurred;
    name;
    note;
    place;
    source;
    text;


    constructor(id: number | null, relationshipId: number, dateMin: string, dateMax: string, dateAccuracy: string, eventOccurred: boolean, name: string, note: string, place: string, source: string, text: string) {
        super();

        this.id = id;
        this.relationshipId = relationshipId;
        this.dateMin = dateMin;
        this.dateMax = dateMax;
        this.dateAccuracy = dateAccuracy;
        this.eventOccurred = eventOccurred;
        this.name = name;
        this.note = note;
        this.place = place;
        this.source = source;
        this.text = text;

    }

    static createFromEvent(event: Event, relationshipId: number) {
        return new RelationshipEventFormDto(
            event.id,
            relationshipId,
            event.date ? event.date.dateMin : "",
            event.date ? event.date.dateMax : "",
            event.date ? event.date.accuracy : "",
            event.eventOccurred,
            event.name,
            event.note || "",
            event.place ? event.place.name : "",
            event.source || "",
            event.detail ? event.detail : "",
        );
    }

    static new(relationshipId: number) {
        return new RelationshipEventFormDto(null, relationshipId, "", "", "", true, "", "", "", "", "");
    }
}








