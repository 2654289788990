import Immerable from "../Immerable";

export default class Date extends Immerable {
    id;
    dateMin;
    dateMax;
    modifiedDateMin;
    modifiedDateMax;
    accuracy;
    modifier;



    constructor(id, dateMin, dateMax,modifiedDateMin,modifiedDateMax, accuracy, modifier) {
        super();

        this.id = id;
        this.dateMin = dateMin;
        this.dateMax = dateMax;
        this.modifiedDateMin = modifiedDateMin;
        this.modifiedDateMax = modifiedDateMax;
        this.accuracy = accuracy;
        this.modifier = modifier;

    }

    static createFromBackend(json) {
        return new Date(
            json.id,
            json.dateMin,
            json.dateMax,
            json.modifiedDateMin,
            json.modifiedDateMax,
            json.accuracy,
            json.modifier,
        );
    }
}