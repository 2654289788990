import Immerable from "../Immerable";

export default class IndividualRelationship extends Immerable {
    id;
    partner;
    events;
    note;
    children;


    constructor(id, partner, events, note, children) {
        super();

        this.id = id;
        this.partner = partner;
        this.events = events;
        this.note = note;
        this.children = children;
    }

    static createFromBackend(json) {
        return new IndividualRelationship(
            json.id,
            json.partner,
            json.events,
            json.note,
            json.children
        );
    }
}