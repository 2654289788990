import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import securityReducer from "./views/security/reducer";
import versionReducer from "./views/versioninfo/reducer";
import individualsReducer from "./views/individuals/reducer";
import individualReducer from "./views/individual/reducer";
import familyReducer from "./views/families/reducer";
import placeReducer from "./views/places/reducer";
import searchReducer from "./views/search/reducer";
import eventReducer from "./components/eventBus/reducer";



export default class StoreSingleton {
    static store;

    static getOrCreateReduxStore() {
        if (StoreSingleton.store) {
            return StoreSingleton.store;
        }

        const appReducer = combineReducers({
            security: securityReducer,
            version: versionReducer,
            individuals: individualsReducer,
            individual: individualReducer,
            families: familyReducer,
            places: placeReducer,
            events: eventReducer,
            search: searchReducer,
        });

        const rootReducer = (state, action) => {
            if (action.type === "INITIALISE_APP") {
                state = undefined;
            }
            return appReducer(state, action);
        };

        StoreSingleton.store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));

        return StoreSingleton.store;
    }
}