import React, {useState} from "react";
import {Button, Card, Col, Container, Form, FormControl, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "./reducer";
import {useHistory} from "react-router";

const Login = () => {
    // const history = useHistory();
    // if (loginUiService.isAuthenticated()) {
    //     history.goBack()
    // }

    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [stayLoggedIn, setStayLoggedIn] = useState(true);

    const {user} = useSelector((state: any) => state.security);
    const history = useHistory();
    if (user.isAuthenticated) {
        history.goBack()
    }

    const handleLogin = () => {
        dispatch(loginUser(username, password, stayLoggedIn))
    };

    const keyPressed = (event: any) => {
        if (event.keyCode === 13) {
            handleLogin();
        }
    };

    return (
        <div className="login">
            <Container>
                <Row>
                    <Col>
                        <Card className="bg-dark border-dark">
                            <Card.Body>
                                <Card.Title>
                                    <h2 className="text-center">Anmeldung</h2>
                                </Card.Title>

                                <Form.Group controlId="loginFormUsername">
                                    <Form.Label>Benutzername</Form.Label>
                                    <FormControl
                                        type={"text"}
                                        onChange={(e: any) => setUsername(e.target.value)}
                                        onKeyDown={keyPressed}
                                        placeholder="Benutzername"
                                    />
                                </Form.Group>

                                <Form.Group controlId="loginFormPassword">
                                    <Form.Label>Passwort</Form.Label>
                                    <FormControl
                                        onChange={(e: any) => setPassword(e.target.value)}
                                        onKeyDown={keyPressed}
                                        type={"password"}
                                        placeholder="Passwort"
                                    />
                                </Form.Group>

                                <Form.Group controlId="loginFormStayLoggedIn">
                                    <Form.Check type="checkbox"
                                                label="Angemeldet bleiben"
                                                checked={stayLoggedIn}
                                                onChange={(e: any) => setStayLoggedIn(e.target.checked)}/>
                                </Form.Group>

                                <Button variant="primary" className="btn-block" onClick={handleLogin}>Login</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;