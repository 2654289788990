import React from "react";

const Loading = () => {
    return (
        <div className="loading-indicator">
            <i className="fas fa-sync fa-spin fa-3x fa-fw"></i>
            <span className="sr-only">Loading...</span>
        </div>
    )
};

export default Loading;
