import React from "react";
import {Col, Row} from "react-bootstrap";
import IndividualCard from "../components/individualcard/IndividualCard";
import Title from "../../../components/title/Title";
import IndividualHelmet from "../components/helmet/IndividualHelmet";

const TabIndividual = ({individual}: any) => {

    return (
        <React.Fragment>
            <IndividualHelmet prefix={"Daten von"} individual={individual}/>

            <Row>
                <Col sm={12} md={6} xl={6}>
                    <Title title={individual.firstName + " " + individual.lastName}/>
                    <IndividualCard individual={individual} open/>
                </Col>
                <Col sm={12} md={6} xl={6}>

                </Col>
                <Col sm={12} md={6} xl={6}>
                    &nbsp;
                </Col>
            </Row>
        </React.Fragment>
    )
};


export default TabIndividual;
