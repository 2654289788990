import React, {useEffect} from "react";
import Title from "../../components/title/Title";
import {useDispatch, useSelector} from "react-redux";
import {fetchListIndividuals} from "./reducer";
import Table from "./table/Table";

const Individuals = () => {

    const {list} = useSelector((state: any) => state.individuals);
    const {individualEvent} = useSelector((state: any) => state.events);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchListIndividuals());
    }, [individualEvent]);


    return (
        <React.Fragment>
            <Title title="Personen"/>
            <Table individuals={list}/>
        </React.Fragment>
    )
};

export default Individuals;