import React, {useState} from "react";
import {Col, Form, FormControl, Row} from "react-bootstrap";
import IndividualFormDto from "../../../models/Form/IndividualFormDto";


interface FormProps {
    form: IndividualFormDto,
    handleChange: any
}

const IndividualForm = ({form, handleChange}: FormProps) => {

    return (
        <React.Fragment>

            <Form.Group controlId="individualFirstName">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Vorname(n):</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <FormControl
                            type={"text"}
                            name="firstName"
                            placeholder="Vorname(n)"
                            value={form.firstName}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="individualLastName">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Nachname:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <FormControl
                            type={"text"}
                            name="lastName"
                            placeholder="Nachname"
                            value={form.lastName}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="individualSex">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Geschlecht:</Form.Label>
                    </Col>
                    <Col xs={4} className="align-center text-center">
                        <i className="fas fa-male"/>&nbsp;
                        <Form.Check
                            type="radio"
                            id="sexM"
                            label="männlich"
                            name="sex"
                            defaultValue="M"
                            checked={form.sex == "M"}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                    <Col xs={4} className="align-center">
                        <i className="fas fa-female"/>&nbsp;
                        <Form.Check
                            type="radio"
                            id="sexF"
                            label="weiblich"
                            name="sex"
                            defaultValue="F"
                            checked={form.sex == "F"}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="individualFirstNamePrefix">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Titel:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <FormControl
                            type={"text"}
                            name="prefixFirstName"
                            placeholder="Dr. / Prof. / Herzog / Amtsrat / ..."
                            value={form.prefixFirstName}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="individualLastNamePrefix">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Präfix Nachname:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <FormControl
                            type={"text"}
                            name="prefixLastName"
                            placeholder="von / van / de / zu / ... "
                            value={form.prefixLastName}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="individualLastNameSuffix">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Präfix Nachname:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <FormControl
                            type={"text"}
                            name="suffixLastName"
                            placeholder="jun. / der Ältere / IV / der Dritte / ... "
                            value={form.suffixLastName}
                            onChange={(e: any) => handleChange(e)}
                        />
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="individualNote">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label>Notiz:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control as="textarea"
                                      name="note"
                                      rows={3}
                                      value={form.note}
                                      onChange={(e: any) => handleChange(e)}/>
                    </Col>
                </Form.Row>
            </Form.Group>


        </React.Fragment>
    )
};


export default IndividualForm;
