import createInstance from "./AxiosFactory";
import Config from "../Config";
import BACKEND_ROUTES from "./BACKEND_ROUTES";

class FamilyClient {
    constructor() {
        this.apiUrl = Config.BACKEND_API + BACKEND_ROUTES.FAMILIES;
        this.axios = createInstance();
    }

    fetchFamilies() {
        return this.axios.get(this.apiUrl);
    }
}

export default FamilyClient;