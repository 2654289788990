enum ROUTES {
    INDEX = "/",
    LOGIN = "/login",
    LOGOUT = "/logout",
    LEGAL = "/legal",
    INDIVIDUAL = "/individual/:id",
    INDIVIDUALS = "/individuals",
    FAMILIES = "/families",
    PLACES = "/places",
    STATISTICS = "/statistics",
    STATISTICS_INDIVIDUALS = "/statistics/individuals",
    STATISTICS_FAMILIES = "/statistics/families",
    SEARCH = "/search"
}

export default ROUTES;