import moment from 'moment';
import Individual from "../models/Individual/Individual";
import Date from "../models/Date/Date";
import EventHelper from "./EventHelper";

export default class DateFormatter {
    static createReadableLifespanDate = (individual: Individual) => {

        if (individual.privacy) {
            return "privat";
        }

        let birthdate = 'unbekannt';
        let deathdate = 'unbekannt';

        const birth = EventHelper.findEventIfExistByName(individual.events, "BIRT");
        const death = EventHelper.findEventIfExistByName(individual.events, "DEAT");

        if (birth && birth.date) {
            birthdate = DateFormatter.readableDateFormatter(birth.date);
        }
        if (death && death.date) {
            deathdate = DateFormatter.readableDateFormatter(death.date);
        }

        const age = DateFormatter.calculateAge(birth, death);

        return birthdate + ' - ' + deathdate + ' (' + age + ')';
    };

    static readableDateFormatter = (date: Date) => {
        let prefix = "";
        let suffix = "";
        const formattedDate = date ? date.modifiedDateMin : 'unbekannt';
        const accuracy = date ? date.accuracy : null;

        if (accuracy == "ABT") {
            prefix = 'um '
        }
        if (accuracy == "AFT") {
            prefix = 'nach '
        }
        if (accuracy == "DEF") {
            prefix = 'vor '
        }
        if (accuracy == "BET") {
            prefix = 'zwischen '
            suffix = ' und ' + date.modifiedDateMax
        }
        if (accuracy == "EST") {
            prefix = 'etwa '
        }
        if (accuracy == "_und") {
            prefix = ' und '
        }

        return prefix + formattedDate + suffix;
    };

    static calculateAge = (birth: any, death: any) => {
        if (birth && birth.date && !death) {
            return moment().diff(moment(birth.date.dateMin, 'YYYY-MM-DD'), 'years', false).toString();
        }
        if (birth && birth.date && death && death.date) {
            return moment(death.date.dateMin, 'YYYY-MM-DD').diff(moment(birth.date.dateMin, 'YYYY-MM-DD'), 'years', false).toString();
        }

        return "unbekannt";
    };

}




