import {toast} from "react-toastify";
import FormClient from "../../backend/FormClient";
import IndividualClient from "../../backend/IndividualClient";


export const saveRelationshipEvent = (form) => {
    const formClient = new FormClient();
    return formClient.saveRelationshipEvent(form)
        .then(response => {
            toast.success("Event wurde gespeichert");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim speichern des Events: ${error}`);
            console.log(error);
        });
};

export const saveIndividualEvent = (form) => {
    const formClient = new FormClient();
    return formClient.saveIndividualEvent(form)
        .then(response => {
            toast.success("Event wurde gespeichert");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim speichern des Events: ${error}`);
            console.log(error);
        });
};

export const deleteEvent = (eventId) => {
    const formClient = new FormClient();
    return formClient.deleteIndividualEvent(eventId)
        .then(response => {
            toast.success("Event wurde gelöscht");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim löschen des Events: ${error}`);
            console.log(error);
        });
};

export const saveIndividual = (form) => {
    const formClient = new FormClient();
    return formClient.saveIndividual(form)
        .then(() => {
            toast.success("Person wurde gespeichert");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim speichern der Person: ${error}`);
            console.log(error);
        });
};

export const getIndividual = (id) => {

    const individualClient = new IndividualClient();

    return individualClient.fetchIndividual(id)
        .then(response => response.data)
        .then(data => {
            return data.data.individual
        })
        .catch(error => {
            toast.error(`Fehler beim Abruf der Person: ${error}`);
            console.log(error);
        });
};

export const saveRelationship = (individual, partner) => {
    const formClient = new FormClient();
    return formClient.saveRelationship(individual, partner)
        .then(() => {
            toast.success("Beziehung wurde gespeichert");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim speichern der Beziehung: ${error}`);
            console.log(error);
        });
};

export const saveRelationshipChild = (relationshipId, child) => {
    const formClient = new FormClient();
    return formClient.saveRelationshipChild(relationshipId, child)
        .then(() => {
            toast.success("Kind wurde gespeichert");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim speichern des Kindes: ${error.response ? error.response.data.message : error}`);
            console.log(error);
        });
};

export const removeRelationshipChild = (childId) => {
    const formClient = new FormClient();
    return formClient.removeRelationshipChild(childId)
        .then(() => {
            toast.success("Kind wurde entfernt");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim entfernen des Kindes: ${error}`);
            console.log(error);
        });
};

export const removeRelationship = (relationshipId) => {
    const formClient = new FormClient();
    return formClient.removeRelationship(relationshipId)
        .then(() => {
            toast.success("Beziehung wurde entfernt");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim entfernen der Beziehung: ${error}`);
            console.log(error);
        });
};

export const saveParents = (parent1, parent2, individual) => {
    const formClient = new FormClient();
    return formClient.saveParents(parent1, parent2, individual)
        .then(() => {
            toast.success("Beziehung wurde entfernt");
            return true;
        })
        .catch(error => {
            toast.error(`Fehler beim speichern der Beziehung: ${error.response ? error.response.data.message : error}`);
            console.log(error);
        });
};