import Immerable from "../Immerable";
import Event from '../Event/Event';

export default class IndividualEventFormDto extends Immerable {
    id;
    individualId;
    dateMin;
    dateMax;
    dateAccuracy;
    eventOccurred;
    name;
    note;
    place;
    source;
    text;


    constructor(id: number | null, individualId: number, dateMin: string, dateMax: string, dateAccuracy: string, eventOccurred: boolean, name: string, note: string, place: string, source: string, text: string) {
        super();

        this.id = id;
        this.individualId = individualId;
        this.dateMin = dateMin;
        this.dateMax = dateMax;
        this.dateAccuracy = dateAccuracy;
        this.eventOccurred = eventOccurred;
        this.name = name;
        this.note = note;
        this.place = place;
        this.source = source;
        this.text = text;

    }

    static createFromEvent(event: Event, individualId: number) {
        return new IndividualEventFormDto(
            event.id,
            individualId,
            event.date ? event.date.modifiedDateMin : "",
            event.date ? event.date.modifiedDateMax : "",
            event.date ? event.date.accuracy : "",
            event.eventOccurred,
            event.name,
            event.note || "",
            event.place ? event.place.name : "",
            event.source || "",
            event.detail ? event.detail : "",
        );
    }

    static new(individualId: number) {
        return new IndividualEventFormDto(null, individualId, "", "", "", true, "", "", "", "", "");
    }
}








