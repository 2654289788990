import React from 'react';
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import App from "./views/App";
import StoreSingleton from "./StoreSingleton";
import * as ReactDOM from "react-dom";
import './styles/App.scss';

const store = StoreSingleton.getOrCreateReduxStore();

ReactDOM.render(
    <Provider store={store}>
        <React.Fragment>
            <ToastContainer />
            <App />
        </React.Fragment>
    </Provider>, document.getElementById("root")
);
