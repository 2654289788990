import React from "react";
import IndividualEventFormDto from "../../../models/Form/IndividualEventFormDto";
import {Col, Form} from "react-bootstrap";
import RelationshipEventFormDto from "../../../models/Form/RelationshipEventFormDto";


interface FormProps {
    form: IndividualEventFormDto | RelationshipEventFormDto,
    handleChange: any,
    renderOptions: any
}

const EventForm = ({form, handleChange, renderOptions}: FormProps) => {

    return (
        <React.Fragment>
            {form.id ?
                null
                :
                <Form.Group controlId="eventType">
                    <Form.Row>
                        <Col xs={4}>
                            <Form.Label>Event auswählen:</Form.Label>
                        </Col>
                        <Col xs={8}>
                            {renderOptions(form)}
                        </Col>
                    </Form.Row>
                </Form.Group>
            }

            <Form.Group controlId="eventAccuray">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label><i className="fas fa-crosshairs"/> Genauigkeit:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control as="select" onChange={(e: any) => handleChange(e)}
                                      name="dateAccuracy"
                                      value={form.dateAccuracy ? form.dateAccuracy : ""}>
                            <option value="">genau</option>
                            <option value="ABT">um</option>
                            <option value="EST">etwa</option>
                            <option value="BET">zwischen</option>
                        </Form.Control>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="eventDateMin">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label><i className="far fa-calendar-alt"/> Datum:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control type="text"
                                      placeholder="Datum"
                                      name="dateMin"
                                      value={form.dateMin}
                                      onChange={(e: any) => handleChange(e)}/>
                    </Col>
                </Form.Row>
            </Form.Group>


            {form.dateAccuracy == "BET"
                ?
                <Form.Group controlId="eventDateMax">
                    <Form.Row>
                        <Col xs={4}>
                            <Form.Label>und:</Form.Label>
                        </Col>
                        <Col xs={8}>
                            <Form.Control type="text"
                                          placeholder="Datum"
                                          name="dateMax"
                                          value={form.dateMax}
                                          onChange={(e: any) => handleChange(e)}/>
                        </Col>
                    </Form.Row>
                </Form.Group>
                :
                null
            }

            <Form.Group controlId="eventPlace">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label><i className="fas fa-map-marked-alt"/> Ort:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control type="text"
                                      placeholder="Ort"
                                      name="place"
                                      value={form.place}
                                      onChange={(e: any) => handleChange(e)}/>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="eventText">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label><i className="fa fa-sticky-note"/> Details:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control type="text"
                                      placeholder="Details"
                                      name="text"
                                      value={form.text}
                                      onChange={(e: any) => handleChange(e)}/>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="eventSource">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label><i className="fa fa-file-export"/> Quelle(n):</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control as="textarea"
                                      name="source"
                                      rows={3}
                                      value={form.source}
                                      onChange={(e: any) => handleChange(e)}/>
                    </Col>
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="eventNote">
                <Form.Row>
                    <Col xs={4}>
                        <Form.Label><i className="fa fa-edit"/> Notiz:</Form.Label>
                    </Col>
                    <Col xs={8}>
                        <Form.Control as="textarea"
                                      name="note"
                                      rows={3}
                                      value={form.note}
                                      onChange={(e: any) => handleChange(e)}/>
                    </Col>
                </Form.Row>
            </Form.Group>

        </React.Fragment>
    )
};


export default EventForm;
