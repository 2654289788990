import React from "react";
import {Col, Row} from "react-bootstrap";
import DateFormatter from "../../../../services/DateFormatter";

const EventDate = ({event}: any) => {

    return (
        <Row>
            <Col xs={1}>
                <i className="fa fa-calendar-alt"/>
            </Col>
            <Col xs={10}>
                {DateFormatter.readableDateFormatter(event.date)}
            </Col>
        </Row>
    )
};

export default EventDate;
