import React from "react";
import {Helmet} from "react-helmet";
import DateFormatter from "../../../../services/DateFormatter";

const IndividualHelmet = ({prefix, individual}: any) => {

    const name = individual.firstName + " " + individual.lastName;
    const date = DateFormatter.createReadableLifespanDate(individual);


    return (
        <Helmet>
            <title>{prefix + " " + name + " [" + date + "]"}</title>
            <meta name="description" content={individual.firstName + " " + individual.lastName}/>
        </Helmet>
    )
};


export default IndividualHelmet;
