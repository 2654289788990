import React, {useEffect, useState} from "react";
import {Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Place from "../../models/Place/Place";

interface PlaceListDto {
    places: []
}

const PlacesList = ({places}: PlaceListDto) => {

    const [query, setQuery] = useState('');
    const [filteredPlaceNames, setFilteredPlaceNames] = useState<Array<Place>>(places);

    useEffect(() => {
        setFilteredPlaceNames(places)
    }, [places]);

    const onChange = (event: any) => {
        setQuery(event.target.value.toLowerCase());
        filterList();
    };

    const filterList = () => {
        const filteredPlaces = places.filter((place: Place) => {
            return place.name.toLowerCase().indexOf(query) != -1; // returns true or false
        });
        setFilteredPlaceNames(filteredPlaces);
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon3">
                                Suche nach:
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" placeholder="Orten" value={query} onChange={onChange}/>
                    </InputGroup>

                </Col>
            </Row>

            <Row>
                <Col>
                    <ul>
                        {filteredPlaceNames.map((place: Place, key: number) => {
                            return <li key={key}>{place.name}</li>;
                        })}
                    </ul>
                </Col>
            </Row>
        </React.Fragment>


    )
};

export default PlacesList;