import React, {useState} from "react";
import FormControlButtons from "./component/FormControlButtons";
import {saveIndividual} from "./reducer";
import IndividualForm from "./forms/IndividualForm";
import IndividualFormDto from "../../models/Form/IndividualFormDto";
import {useDispatch} from "react-redux";
import {eventIndividualSaved} from "../../components/eventBus/reducer";


interface FormProps {
    individual: IndividualFormDto,
    setShow?: any
}

const IndividualFormContainer = ({individual, setShow}: FormProps) => {
    const [form, setForm] = useState<any>(individual);
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSave = () => {
        saveIndividual(form).then(() => {
            dispatch(eventIndividualSaved());
            setShow(false)
        });
    };

    const onCancel = () => {
        setForm(individual);
        setShow(false);
    };

    const disabledUntil = () => {
        return form.firstName == "" || form.lastName == ""
    };


    return (
        <React.Fragment>
            <IndividualForm form={form} handleChange={handleChange}/>

            <FormControlButtons onSave={onSave} onCancel={onCancel} saveDisabled={disabledUntil()}/>

        </React.Fragment>
    )
};


export default IndividualFormContainer;
