import React from "react";
import {Col, Row} from "react-bootstrap";

const EventText = ({event}: any) => {

    return (
        <Row>
            <Col xs={1}>
                <i className="fa fa-sticky-note"/>
            </Col>
            <Col xs={10}>
                {event.detail ? event.detail : ""}
            </Col>
        </Row>
    )
};


export default EventText;
