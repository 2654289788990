import React from "react";
import {NavLink} from "react-router-dom";
import {Nav} from "react-bootstrap";

interface Props {
    to: string;
    icon: string;
    name: string;
    onClick?: any;
}

const NavigationLink = ({to, icon, name, onClick}: Props) => {


    return (
        <Nav.Item onClick={onClick}>
            <NavLink activeClassName="active" className="nav-link" to={to} exact={true}>
                <i className={icon}></i> {name}
            </NavLink>
        </Nav.Item>
    )
};

export default NavigationLink;